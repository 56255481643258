import { useState } from "react";
import { useNavigate } from "react-router-dom";
import userImg from "../../assets/logo_white.svg"; 
import gymImg from "../../assets/logo_white.svg"; 
import logo from "../../assets/MainLogo2.svg"; 

const Onboarding = () => {
    const navigate = useNavigate();
    const [isGymOnboarding, setIsGymOnboarding] = useState(null); // Start with null so nothing is preselected
    const [selected, setSelected] = useState("");

    // Function to handle the next button click
    const handleNext = () => {
        if (isGymOnboarding !== null) {
            console.log('isGymOnboarding : ', isGymOnboarding);
            navigate("/GymCred", { state: { isGymOnboarding } });
        } else {
            alert("Please select an onboarding type.");
        }
    };

    return (
        <div className="relative min-h-screen flex flex-col items-center justify-center bg-tertiary px-4">
            
            {/* Train Rex Logo at the Top */}
            <div className="absolute top-6 w-full flex justify-center">
                <img src={logo} alt="Train Rex Logo" className="w-24 h-24" />
            </div>

            {/* Title */}
            <h2 className="absolute top-32 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl lg:text-4xl font-semibold text-white">
                Select Onboarding Type:
            </h2>

            {/* Selection Box Container */}
            <div className="flex flex-col items-center justify-center w-full max-w-4xl mt-24 gap-6 md:flex-row">
                
                {/* Gym Onboarding Selection */}
                <div
                    onClick={() => { setIsGymOnboarding(true); setSelected("Gym"); }}
                    className={`relative flex flex-col items-center justify-center w-full md:w-1/2 h-72 bg-secondary rounded-2xl cursor-pointer 
                        hover:shadow-lg transition-transform hover:scale-105 p-6 border-4
                        ${selected === "Gym" ? "border-purple bg-lightPurple" : "border-transparent"}`}
                >
                    <h2 className="text-3xl font-bold text-white mb-4">Gym Onboarding</h2>
                    <img src={gymImg} alt="Gym Onboarding" className="w-32 h-32 object-contain" />
                </div>

                {/* Studio Onboarding Selection */}
                <div
                    onClick={() => { setIsGymOnboarding(false); setSelected("Studio"); }}
                    className={`relative flex flex-col items-center justify-center w-full md:w-1/2 h-72 bg-secondary rounded-2xl cursor-pointer 
                        hover:shadow-lg transition-transform hover:scale-105 p-6 border-4
                        ${selected === "Studio" ? "border-purple bg-lightPurple" : "border-transparent"}`}
                >
                    <h2 className="text-3xl font-bold text-white mb-4">Studio Onboarding</h2>
                    <img src={userImg} alt="Studio Onboarding" className="w-32 h-32 object-contain" />
                </div>
            </div>

            {/* Next Button */}
            <button
                className={`mt-8 px-8 py-2 text-white rounded-full bg-purple lg:text-xl transition-all duration-500 
                    ${selected === "" ? "cursor-not-allowed opacity-50" : "hover:bg-lightPurple"}`}
                disabled={isGymOnboarding === null}
                onClick={handleNext}
            >
                Next
            </button>
        </div>
    );
};

export default Onboarding;
