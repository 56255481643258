import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

// Import slide images
import slide1 from "../../assets/Proposal/1.png";
import slide2 from "../../assets/Proposal/2.png";
import slide3 from "../../assets/Proposal/3.png";
import slide4 from "../../assets/Proposal/4.png";
import slide5 from "../../assets/Proposal/5.png";
import slide6 from "../../assets/Proposal/6.png";

// Array of slides
const slides = [slide1, slide2, slide3, slide4, slide5, slide6];

/**
 * UserOnboarding Component
 *
 * * @param {Function} setIsSigningIn    -   function to display the signIn Modal
 */

const UserOnboarding = ({setIsSigningIn}) => {
    const navigate = useNavigate();
    const [currentSlide, setCurrentSlide] = useState(0);

    /**
     * Moves to the next slide.
     */
    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    /**
     * Moves to the previous slide.
     */
    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-tertiary">
            {/* Carousel */}
            <div className="relative w-3/4 h-2/3 flex items-center justify-center">
                {/* Slide Image */}
                <motion.img
                    key={currentSlide}
                    src={slides[currentSlide]}
                    alt={`Slide ${currentSlide + 1}`}
                    className="w-full h-full object-contain rounded-lg shadow-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                />

                {/* Left Navigation Arrow */}
                <button
                    className="absolute left-4 text-4xl text-white bg-black bg-opacity-50 p-3 rounded-full hover:bg-opacity-75 transition"
                    onClick={prevSlide}
                >
                    &lt;
                </button>

                {/* Right Navigation Arrow */}
                <button
                    className="absolute right-4 text-4xl text-white bg-black bg-opacity-50 p-3 rounded-full hover:bg-opacity-75 transition"
                    onClick={nextSlide}
                >
                    &gt;
                </button>
            </div>

            {/* New & Returning User Buttons */}
            <div className="flex gap-6 mt-8">
                <button className="bg-lightSecondary rounded-3xl px-6 py-2 hover:bg-secondary text-lg" onClick={() => navigate("/GymCredOnboarding")}>
                    New User
                </button>
                <button className="bg-lightPurple rounded-3xl px-6 py-2 hover:bg-purple text-lg" onClick={() => setIsSigningIn(true)}>
                    Returning User
                </button>
            </div>
        </div>
    );
};

export default UserOnboarding;
