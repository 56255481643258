import React from "react";
import userImg from "../../assets/logo_white.svg"; // Replace with correct path
import gymImg from "../../assets/logo_white.svg"; // Replace with correct path
import logo from "../../assets/MainLogo2.svg"; // Train Rex logo

/**
 * SelectionScreen Component
 * 
 * @param {Function} handleSelection    -   Function to handle user selection (either "user" or "fitness")
 * 
 * @returns {JSX.Element}               -   A selection screen with two large clickable rectangles.
 */

const SelectionScreen = ({ handleSelection }) => {
    return (
        <div className="relative flex flex-col items-center justify-center min-h-screen bg-tertiary px-4">
            
            {/* Logo fixed at the top */}
            <div className="absolute top-6 w-full flex justify-center px-4">
                <img src={logo} alt="Train Rex Logo" className="h-16" />
            </div>

            {/* Selection Buttons Container - Centered on the screen */}
            <div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl mt-20 md:mt-0 px-4">
                {/* User Selection */}
                <div
                    className="relative flex flex-col items-center justify-center w-full md:w-1/2 h-72 bg-secondary rounded-2xl cursor-pointer hover:shadow-lg transition-transform hover:scale-105 p-6"
                    onClick={() => handleSelection("user")}
                >
                    <h2 className="text-3xl font-bold text-white mb-4">User</h2>
                    <img src={userImg} alt="User" className="w-32 h-32 object-contain" />
                </div>

                {/* Fitness Center Selection */}
                <div
                    className="relative flex flex-col items-center justify-center w-full md:w-1/2 h-72 bg-secondary rounded-2xl cursor-pointer hover:shadow-lg transition-transform hover:scale-105 p-6"
                    onClick={() => handleSelection("fitness")}
                >
                    <h2 className="text-3xl font-bold text-white mb-4 text-center">Fitness Center</h2>
                    <img src={gymImg} alt="Fitness Center" className="w-32 h-32 object-contain" />
                </div>
            </div>
        </div>
    );
};

export default SelectionScreen;
