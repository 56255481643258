import React, { useEffect, useState } from "react";
import OverviewPage from "./Overview";
import CityWiseDataTab from "./CityWiseDataTab";
import DateWiseDataTab from "./DateWiseDataTab";
import Finance from "./Finance";
import UserAttendance from "./UserAttendance";
import { ClientsManagement } from "./ClientsManagement";
import { getGymProfile } from "../../../helperFunctions/DashboardScenesHelpers/ProfileHelpers";
import icon from "../../../assets/Icon.jpeg";
import ClassManagement from "./ClassManagement";
import logoSVG from "../../../assets/Website_content/TR_Website_content-01.svg";
import { LocationIcon } from "../../../assets/WelcomeScreenImage";
import { themeColors } from "../../../theme";
import SalesPage from "./SalesPage";
import StaffManagement from "./StaffManagementTab";
import { signOut } from "firebase/auth";
import { auth } from '../../../config/firebase';
import { fetchGymLogo } from "../../../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/BasicDataFetch";
import StaffPage from "./StaffPage";
import DisplayProfile from "./Profile";

const DashboardScenesController = ({ user }) => {
    // State for managing the currently active tab
    const [activeTab, setActiveTab] = useState('Overview');

    const [logoURL, setLogoURL] = useState(null);


    // State for storing the gym's profile data
    const [gymData, setGymData] = useState(null);


    // State for managing the menu's open/close state in mobile view
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    // Fetch gym profile data when the component mounts or when the user ID changes
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                // Fetch gym profile data using the user ID
                const data = await getGymProfile(user.uid);
                setGymData(data); // Set the fetched data in the state
            } catch (error) {
                console.error("Error fetching gym profile:", error); // Log any errors during fetching
            }
        };
        fetchProfile(); // Call the fetch function
    }, [user.uid]); // Dependency array includes user ID to refetch data when it changes

    // To fetch the gym logo's URL
    // useEffect(() => {
    //     const fetchAndSetGymLogo = async () => {
    //         if (gymData) {
    //             const gymLogoUrl = await fetchGymLogo(gymData.gymName);
    //             setLogoURL(gymLogoUrl);
    //         }
    //     };

    //     fetchAndSetGymLogo();
    // }, [gymData]);



    // Toggle the menu open/close state for mobile view
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    // Render the appropriate content based on the active tab
    const renderContent = () => {
        if (!gymData) {
            return (
                <>
                    <div>
                        {/* Show a loading indicator until gymData is loaded */}
                        Loading...
                    </div>
                    <div className="w-[25vw] px-4">
                        <button
                            onClick={handleSignOut}
                            className="w-full py-1 bg-tertiary hover:bg-red hover:text-white rounded-xl focus:border-secondary text-secondary focus:text-primary font-bold"
                        >
                            Log Out
                        </button>
                    </div>
                </>
            )
        }

        // Return the appropriate component based on the active tab
        // passed the activeTab as props to the pages which have calendar component and the graph component
        switch (activeTab) {
            case 'Overview':
                return <OverviewPage gymData={gymData} activeTab="overview" />;
            case 'Profile':
                // user.uid is the gymID
                return <DisplayProfile gymID={user.uid}/>;
            case 'DateWiseData':
                return <DateWiseDataTab />;
            case 'CityWiseData':
                return <CityWiseDataTab />;
            case 'ClientsManagement':
                return <ClientsManagement gymData={gymData} activeTab="ClientsManagement" />;
            case 'Finance':
                return <Finance gymData={gymData} activeTab="Finance" />;
            case 'UserAttendance':
                return <UserAttendance gymData={gymData} />;
            case 'StaffManagement':
                return <StaffPage gymData={gymData} />;
            case 'ClassManagement':
                return <ClassManagement gymData={gymData} />;
            case 'SalesPage':
                return <SalesPage gymData={gymData} />;
            default:
                return <OverviewPage gymData={gymData} />;
        }
    };


    // Handle user sign-out
    const handleSignOut = async () => {
        try {
            await signOut(auth); // Sign out the user using Firebase auth
        } catch (error) {
            console.error("Error signing out:", error); // Log any errors during sign-out
        }
    };


    return (
        <div className="flex flex-col lg:flex-row bg-secondary min-h-screen max-w-full">
            {/* Navigation Bar */}
            <div className={`w-full lg:w-[25vw] min-w-[13vw] max-w-[13vw] lg:max-w-none`}>
                <div className="flex flex-row lg:flex-col items-center justify-between px-4 py-4 lg:py-6 lg:px-8 w-full bg-secondary">
                    <a className="flex items-center">
                        {/* Logo */}
                        <img src={logoSVG} alt="Logo" className="h-7" />
                    </a>
                    {/* Mobile menu toggle button */}
                    <button
                        className={`lg:hidden text-primary focus:outline-none ${isMenuOpen ? 'absolute top-6 right-6 z-50' : ''}`}
                        onClick={toggleMenu}
                    >
                        <svg
                            className="w-8 h-8"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                            ></path>
                        </svg>
                    </button>
                </div>


                <div
                    className={`lg:flex flex-col items-center lg:space-y-5 lg:pt-24 ${isMenuOpen ? "flex flex-col w-[70vw] h-screen bg-secondary px-6 py-4 justify-start items-start absolute top-0 left-0 z-50" : "hidden"
                        } lg:block`}
                >
                    {/* Display gym data if available */}
                    {gymData && (
                        <div className="flex justify-center items-center text-primary flex-col space-y-2 pt-12 lg:pt-0 pb-4">
                            <img
                                src={gymData.gymLogo ? gymData.gymLogo : logoSVG}
                                alt="Logo"
                                className="w-24 h-24 lg:w-28 lg:h-28 mb-2 rounded-full bg-secondary"
                            />
                            <div onClick={() => {
                                setActiveTab("Profile");
                                setIsMenuOpen(false);
                            }} id="Gym Name" className="text-xl lg:text-3xl font-bold text-center">
                                {gymData.gymDisplayName}
                            </div>
                            <div id="Gym Location" className="flex flex-row items-center justify-center">
                                <LocationIcon className="w-4 h-4 lg:w-6 lg:h-4" color={themeColors.lightAccent} />
                                <p className="text-sm lg:text-base font-bold text-tertiary text-center">
                                    {gymData.locality}
                                </p>
                            </div>

                            {/* Navigation buttons */}
                            <div className="px-4 py-6 lg:py-12 space-y-3 lg:space-y-5 w-full">
                                <button
                                    onClick={() => {
                                        setActiveTab("Overview");
                                        setIsMenuOpen(false);
                                    }}
                                    className="w-full py-2 bg-tertiary rounded-xl text-secondary font-bold"
                                >
                                    Overview
                                </button>
                                <button
                                    onClick={() => {
                                        setActiveTab("ClientsManagement");
                                        setIsMenuOpen(false);
                                    }}
                                    className="w-full py-2 bg-tertiary rounded-xl text-secondary font-bold"
                                >
                                    Clients
                                </button>
                                <button
                                    onClick={() => {
                                        setActiveTab("Finance");
                                        setIsMenuOpen(false);
                                    }}
                                    className="w-full py-2 bg-tertiary rounded-xl text-secondary font-bold"
                                >
                                    Finances
                                </button>
                                <button
                                    onClick={() => {
                                        setActiveTab("ClassManagement");
                                        setIsMenuOpen(false);
                                    }}
                                    className="w-full py-2 bg-tertiary rounded-xl text-secondary font-bold"
                                >
                                    Classes
                                </button>
                                <button
                                    onClick={() => {
                                        setActiveTab("StaffManagement");
                                        setIsMenuOpen(false);
                                    }}
                                    className="w-full py-2 bg-tertiary rounded-xl text-secondary font-bold"
                                >
                                    Staff
                                </button>
                                <button
                                    onClick={() => {
                                        setActiveTab("SalesPage");
                                        setIsMenuOpen(false);
                                    }}
                                    className="w-full py-2 bg-tertiary rounded-xl text-secondary font-bold"
                                >
                                    Sales
                                </button>
                            </div>


                            {/* Log out button */}
                            <div className="w-full px-4">
                                <button
                                    onClick={handleSignOut}
                                    className="w-full py-1 bg-tertiary hover:bg-red hover:text-white rounded-xl focus:border-secondary text-secondary focus:text-primary font-bold"
                                >
                                    Log Out
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>


            {/* Main Content Area */}
            <div className={`flex w-full bg-tertiary py-4 px-2 lg:px-4 rounded-l-[48px] ${isMenuOpen ? 'opacity-40' : ''}`}>
                {renderContent()} {/* Render content based on the active tab */}
            </div>
        </div>
    );
};


export default DashboardScenesController;