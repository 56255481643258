/**
 * Displays a message when a receipt is not present.
 * 
 * @param {String} reason   -   Reason for ingenuinity
 * @returns {JSX.Element}   -   Renders a component indicating that the receipt is not available.
 */
const InGenuineReceipt = ({reason}) => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-6 bg-lightTertiary rounded-lg shadow-md">
            <h2 className="text-lg font-semibold text-primary">Invalid Member</h2>
            <p className="text-secondary text-sm mt-2">{reason}</p>
        </div>
    );
};

export default InGenuineReceipt;
