/**
 * ReceiptsConfirmation.js
 * 
 * This page confirms the receipts of a user
 * After the owner scans the QR Code
 */

import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { auth } from "../config/firebase";
import LoadingSpinner from "../components/LoadingSpinner";
import { fetchPaymentReceipt } from "../helperFunctions/ReceiptHelpers/FetchReceipt";
import { conditionsForVerification } from "../helperFunctions/ReceiptHelpers/ConstraintsforVerification";
import { updateReceipt } from "../helperFunctions/ReceiptHelpers/UpdateReceipt";
import ReceiptNotPresent from "../components/ReceiptConfirmation/Status1";
import InGenuineReceipt from "../components/ReceiptConfirmation/Status2";
import PerfectReceipt from "../components/ReceiptConfirmation/Status3";

/**
 * Confirms whether the qr code displayed by the user is genuine or not
 * @param {String} receiptID    -   Taken form the slug, this is the receipt ID generated through the marketplace
 */
const ReceiptsConfirmation = () => {

    const {receiptID}                       =   useParams();
    const navigate                          =   useNavigate();

    // Define Variables
    const [user,        setUser         ]   =   useState([]);
    const [isLoggedIn,  setIsLoggedIn   ]   =   useState(false);
    const [loading,     setLoading      ]   =   useState(true);
    const [receiptData, setReceiptData  ]   =   useState([]);
    const [verifying,   setVerifying    ]   =   useState(false);
    const [status,      setStatus       ]   =   useState(0);
    /**
     * Status Symbols   :   0   -   Pending
     *                  :   1   -   Receipt not Present
     *                  :   2   -   Already Verified or expired
     *                  :   3   -   Perfect Receipt
     *                  :   4   -   Internal Error in Updating Receipt
     */
    const [errorDeets,  setErrorDeets   ]   =   useState();
            
    /** 
     * Checks if the user is logged in or not
     * If they are,
     * store the user information in user variable
     */
    useEffect(() => {
        const authStateChangeListener = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                setIsLoggedIn(true);
            } else {
                setUser(null);
                setIsLoggedIn(false);
            }
            setLoading(false);
        });
        // Cleanup the listener on component unmount
        return () => authStateChangeListener();
    }, []);

    /**
     * 
     */
    useEffect(() => {
        const verifyReceipt = async () => {
            // Update the status for the user to verifying
            setVerifying(true);

            // Fetch the receipt Data first
            const fetchedReceipt = await fetchPaymentReceipt(receiptID);
            if (!fetchedReceipt)
                setStatus(1);
            setReceiptData(fetchedReceipt.docData);

            // Check if the receipt is genuine
            const response = conditionsForVerification(fetchedReceipt.docData, user.uid);
            const isGenuine = response.status;
            setErrorDeets(response.reason);

            // Update the receipt to verified
            let isUpdated = false;
            if (isGenuine)
                // Only update the receipt, 
                // if the receipt is genuine
                isUpdated = await updateReceipt(fetchedReceipt.docRef);
            else 
                // If the receipt is already verfied
                setStatus(2);

            // If everything goes well
            if (isUpdated)
                setStatus(3);
            // If Problem in updation
            else if (isGenuine) 
                setStatus(4);

            // After everything is done
            setVerifying(false);
        }

        /**
         * What Status Symbols Represent    :   0   -   Pending
         *                                  :   1   -   Receipt not Present
         *                                  :   2   -   Already Verified or expired
         *                                  :   3   -   Perfect Receipt
         *                                  :   4   -   Internal Error in Updating Receipt
         */

        if (user) 
            verifyReceipt();

    }, [user, receiptID]);


    // If loading is not completed
    if (loading) {
        return (
            <div className="flex flex-col min-h-screen bg-secondary py-8 justify-center">
                <LoadingSpinner />
            </div>
        )
    }
    else if (!user && !isLoggedIn) {
        alert('Please Login First!');
        navigate('/');
        return;
    }

    if (verifying) {
        return (
            <div className="flex flex-col min-h-screen bg-tertiary py-8 justify-center">
                <LoadingSpinner />
            </div>
        )
    }
    
    // Return Statements for different Statuses
    if (status === 1)           //  Receipt not present status
        return (
            <ReceiptNotPresent />
        )
    else if (status === 2)      // Already Verified, or is expired or belongs to the other gym
        return (
            <InGenuineReceipt reason={errorDeets} />
        )
    else if (status === 3 || status === 4) {
        return (
            <PerfectReceipt receiptData={receiptData} />
        )
    }

}

export default ReceiptsConfirmation;