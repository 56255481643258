/**
 * Displays a message when a receipt is not present.
 * 
 * @returns {JSX.Element} - Renders a component indicating that the receipt is not available.
 */
const ReceiptNotPresent = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-6 bg-lightTertiary rounded-lg shadow-md">
            <h2 className="text-lg font-semibold text-primary">Receipt Not Found</h2>
            <p className="text-secondary text-sm mt-2">The requested receipt is not available. Please check the details and try again.</p>
        </div>
    );
};

export default ReceiptNotPresent;
