import React, { useEffect, useState } from "react";
import { CheckCircle } from "lucide-react";
import { motion } from "framer-motion";

/**
 * PrefectReceipt Component
 * Displays a verified receipt with an animation effect.
 *
 * @param {Object} receiptData  -   The receipt object containing user details.
 * @returns {JSX.Element}       -   Rendered PrefectReceipt component.
 */
const PerfectReceipt = ({ receiptData }) => {
    const [showReceipt, setShowReceipt] = useState(false);

    useEffect(() => {
        // Show receipt after a short delay to allow animation
        const timer = setTimeout(() => {
            setShowReceipt(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-6 bg-lightTertiary rounded-lg shadow-md">
            {/* Animated Tick Icon */}
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <CheckCircle size={50} className="text-green-500" />
            </motion.div>

            
            {showReceipt && (
                <div className="mt-4 p-4 bg-white rounded-lg shadow-md w-full max-w-md">
                    <p className="text-lg font-bold text-purple">{receiptData.user.displayName}</p>
                    <p className="text-sm text-secondary">Gender : {receiptData.gender}</p>
                    <p className="text-sm text-secondary">Valid Till : {new Date(receiptData.endDate).toLocaleDateString()}</p>
                </div>
            )}
        </div>
    );
};

export default PerfectReceipt;
