import { sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import Modal from "react-modal";
import { useState } from "react";
import { auth, dbGym } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Importing icons from react-icons
import { gymList, studioList } from "../../../components/Databases";

const GymSignIn = ({ onSignIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // 🔹 Forgot Password Handler
    const handleForgotPassword = async () => {
        if (!email) {
            alert("Please enter your email to reset password.");
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            alert("Password reset email sent! Check your inbox.");
        } catch (error) {
            console.error("Error resetting password:", error.message);
            alert("Failed to send reset email. Make sure the email is correct.");
        }
    };

    return (
        <>
            <Modal
                isOpen={true}
                onRequestClose={() => navigate('/')}
                contentLabel="Sign In"
                className="fixed inset-0 flex items-center justify-center z-50 text-black"
                overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75"
            >
                <div className="bg-secondary rounded-lg shadow-lg p-6 sm:p-10 md:p-16 lg:p-20 w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl relative flex flex-col items-center space-y-6">
                    
                    {/* Business Platform Intro Message */}
                    <div className="text-center">
                        <h3 className="text-xl sm:text-2xl md:text-3xl text-primary font-bold w-full">
                            Welcome to Train Rex Business
                        </h3>
                        <p className="mt-4 text-sm sm:text-md text-gray-700">
                            This platform is exclusively for gym owners and fitness businesses to manage their operations.
                            <br /><br />
                            Interested in collaborating? <a href="mailto:info@trainrex.org" className="text-primary underline">Contact Us</a>.
                            <br /><br />
                            Looking for fitness classes, trainers, or memberships? Visit 
                            <a href="https://trainrexofficial.com" target="_blank" rel="noopener noreferrer" className="text-primary underline"> trainrexofficial.com</a>.
                        </p>
                    </div>

                    {/* Login Section */}
                    <div className="flex flex-col space-y-4 w-full">
                        <h3 className="text-xl sm:text-2xl md:text-3xl text-primary font-bold text-center w-full">
                            Gym Sign In
                        </h3>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter Your Email"
                            className="text-primary px-4 py-2 border rounded-full bg-tertiary w-full"
                        />
                        <div className="relative w-full">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter Your Password"
                                className="text-primary px-4 py-2 border rounded-full bg-tertiary w-full pr-10"
                            />
                            <button 
                                type="button" 
                                onClick={togglePasswordVisibility}
                                className="absolute right-4 top-3 text-xl text-primary"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        <button
                            onClick={() => handleSignIn(email, password, onSignIn, gymList, studioList, navigate)}
                            className="bg-primary text-tertiary px-4 py-2 rounded-full hover:bg-tertiary hover:text-primary transition w-full sm:w-1/2 self-center"
                        >
                            Sign In
                        </button>

                        {/* 🔹 Forgot Password Link */}
                        <button
                            onClick={handleForgotPassword}
                            className="text-primary underline text-sm hover:text-tertiary transition self-center mt-2"
                        >
                            Forgot your password?
                        </button>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default GymSignIn;

/**
 * Handles Sign In after user enters the credentials
 * @param {string} email
 * @param {string} password
 * @param {function} onSignIn
 */
const handleSignIn = async (email, password, onSignIn, gymList, studioList, navigate) => {
    try {
        // Step 1: Sign in since data is complete
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        console.log('User signed in:', user.uid);

        // Step 4: Proceed with normal sign-in
        onSignIn(user);

    } catch (error) {
        console.error('Error signing in:', error.message);
        alert(`Error signing in: ${error.message}`);
    }
};
