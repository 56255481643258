import React, { useState, useEffect } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, createUserWithEmailAndPassword, linkWithCredential, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../config/firebase';
import { doc, setDoc } from '@firebase/firestore';
import { gymList, studioList } from '../../components/Databases';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleSendPersonalizedMessage } from '../../helperFunctions/basicHelper';
import { checkIfUserIsAlreadyRegistered } from '../../helperFunctions/GymFormHelper';

/**
 * Renders a page to sign a gym up
 */
function SignUpWithTNC() {
    const location                                                  = useLocation();
    const navigate                                                  = useNavigate();
    console.log("HI TNC", location)
    const [isGymOnboarding          , setIsGymInboarding          ] = useState(null);
    const [email                    , setEmail                    ] = useState('');
    const [password                 , setPassword                 ] = useState('');
    const [confirmPassword          , setConfirmPassword          ] = useState('');
    const [phone                    , setPhone                    ] = useState('');
    const [showEmailPasswordForm    , setShowEmailPasswordForm    ] = useState(false);
    const [phoneNumberSubmitting    , setPhoneNumberSubmitting    ] = useState(false);
    const [submittingEmail          , setSubmittingEmail          ] = useState(false);
    const [countryCode              ,                             ] = useState('+91')


    useEffect (() => {

        // Go back to GymCred if the state is not present
        if (location?.state === null || location?.state === undefined) 
            navigate('/GymCredOnboarding');
        // Otherwise move forward
        else
            setIsGymInboarding(location.state);

    }, [location.state]);

    // useEffect (() => {
    //     window.location.reload();
    // }, [isGymOnboarding]);


    /**
     * Initialises Recaptcha upon page loading
     * Used to filter out bots
     */
    useEffect(() => {
        const initializeRecaptcha = async () => {
            if (!window.recaptchaVerifier) {
                try {
                window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                    },
                    'expired-callback': () => {
                    }
                });
                await window.recaptchaVerifier.render();
                } catch (error) {
                // console.error("Error initializing reCAPTCHA", error);
                }
            }
        };
        if (isGymOnboarding !== null) initializeRecaptcha();
    }, [isGymOnboarding]);

    /**
     * Handles the submission of phone Number
     * Checks if recaptcha is verified, if not, returns
     * If yes, authenticates user with their phone number
     * @param {String} phoneNumber 
     * @param {RecaptchaVerifier} appVerifier
     */
    const handlePhoneNumberSubmit = async () => {
        setPhoneNumberSubmitting(true);
        
        if(phone?.length !== 10) {
            alert('Please enter a 10 digit phone number')
            setPhoneNumberSubmitting(false)
            return; 
        }
        
        const userData = await checkIfUserIsAlreadyRegistered(phone);
        
        if(userData !== null && userData?.email !== '') {
            alert('You are already registered, please login, if on login, gym data is not complete, you will be redirected to gym form')
            navigate('/')
            return;
        }
            
        if (!window.recaptchaVerifier) {
          console.error("reCAPTCHA verifier is not initialized");
          return;
        }
    
        setPhoneNumberSubmitting(false);
        setShowEmailPasswordForm(true);
    };

    /**
     * Adds user to the database
     * @param {User} user 
     * @returns {boolean}
     */
    const addUserToDoc = async(user) => {
        try {
        const userRef = doc(db, isGymOnboarding ? gymList : studioList, user.uid);
        await setDoc(userRef, {
            phoneNumber :   phone,
            email       :   email,
        });
        return true;
        } catch (error) {
        // console.error("Error adding user to Firestore", error);
        return false;
        }
    };

    /**
     * Checks if both passwords are equal
     * Calls addUserToDoc
     * Links User credentials
     * Navigates to /GymForm
     * @param {*} e 
     * @returns 
     */
    const handleSignUp = async (e) => {
        e.preventDefault();
        setSubmittingEmail(true);
        const fullPhoneNumber = `${countryCode}${phone}`;

        if (password !== confirmPassword) {
            alert('Passwords do not match.');
            setSubmittingEmail(false);
            return;
        }

        try {

        
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        if (await addUserToDoc(user)) {
            // await updateProfile(user, { displayName: name });

            // If everything is successful, navigate to GymForm page with user data
            try {
                // Extracting necessary data from user object
                const userData = {
                    uid: user.uid,
                    email: user.email,
                    phoneNumber: fullPhoneNumber,
                };
                
                // Sending personalized messages to gym owners for better communication and standardization
                handleSendPersonalizedMessage('sms', phone, 'gymCred')
                handleSendPersonalizedMessage('email', email, 'gymCred')

                // Navigating to the gym form to fill in the details
                if(isGymOnboarding) {
                    setSubmittingEmail(false);
                    navigate('/GymForm', { state: { user: userData } });
                } else {
                    setSubmittingEmail(false);
                    navigate('/StudioForm', { state: { user: userData } });
                }
            } catch (error) {
                setSubmittingEmail(false);
            }
        }
    } catch (error) {
        console.error("Error during sign up", error);
    
        alert('Error during sign up: ' + error.message);
    
        // In case of error, navigate to the start
        navigate('/GymCredOnboarding');
    }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-tertiary">
            <div className="w-[90vw] lg:max-w-1/2 lg:w-1/2 bg-secondary rounded-lg shadow-md overflow-hidden">
                <div className="p-6 sm:p-8">

                    {/* Display state for testing */}
                    {/* {gymOrStudioSelected && (
                        <div className="mt-4 text-green-700">
                        Onboarding Type Selected: {isGymOnboarding ? "Gym" : "Studio"}
                        </div>
                    )} */}

                    <h2 className="text-2xl font-bold text-center text-primary">Register Your {isGymOnboarding ? 'Gym ' : 'Studio'}</h2>
                    {/* Show registration forms only after selection */}
                    <div>
                        {!showEmailPasswordForm ? (
                        <div>
                            <div className="mt-8 space-y-6">
                                <div className="rounded-md shadow-sm -space-y-px">
                                    <div>
                                        <label htmlFor="phone" className="sr-only">Phone number</label>
                                        <input
                                            id="phone"
                                            name="phone"
                                            type="tel"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            required
                                            className="appearance-none bg-white rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-800 text-black rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                            placeholder="Phone number"
                                        />
                                    </div>
                                </div>
                                <button
                                    onClick={handlePhoneNumberSubmit}
                                    disabled={phoneNumberSubmitting}
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-xl text-primary bg-tertiary hover:bg-primary hover:text-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:black"
                                >
                                    Next
                                </button>
                                <div id="recaptcha-container"></div>
                                <div className='flex flex-col lg:flex-row justify-center items-center'>
                                    <div>By Signing Up, you agree to our &nbsp;</div>
                                    <a href='https://trainrex.org/TandCPage' className='text-tertiary'>Terms and Conditions</a>
                                </div>
                            </div>
                        </div>
                        ) : (
                        <form className="mt-8 space-y-6" onSubmit={handleSignUp}>
                            <div className="rounded-md shadow-sm space-y-4">
                            {/* Email Address */}
                            <div className="mt-4">
                                <label htmlFor="email" className="sr-only">
                                Email address
                                </label>
                                <input
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                placeholder="Email address"
                                />
                            </div>

                            {/* Password */}
                            <div className="mt-4">
                                <label htmlFor="password" className="sr-only">
                                Password
                                </label>
                                <input
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                placeholder="Password"
                                />
                            </div>

                            {/* Confirm Password */}
                            <div className="mt-4">
                                <label htmlFor="confirm-password" className="sr-only">
                                Confirm Password
                                </label>
                                <input
                                id="confirm-password"
                                name="confirm-password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                placeholder="Confirm Password"
                                />
                            </div>
                            </div>
                            <div>
                            <button
                                type="submit"
                                disabled={submittingEmail}
                                className={`group relative w-full flex justify-center py-2 px-4 border border-none text-md font-medium rounded-md text-primary bg-tertiary hover:bg-primary hover:text-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black shadow-md ${submittingEmail ? 'cursor-not-allowed bg-opacity-50' : ''}`}
                            >
                                {submittingEmail ? 'Signing Up...' : 'Sign Up'}
                            </button>
                            </div>
                        </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpWithTNC;
