import { algoliasearch } from 'algoliasearch';
import { AlgoliaAPIKey, AlgoliaAdminAPIKey } from '../../config/SecretKeys';

const client = algoliasearch(AlgoliaAPIKey, AlgoliaAdminAPIKey);

/**
 * Add the gyms in the index
 * @param {*} listOfGyms 
 * @returns 
 */
export const addGymToAlgolia = async (listOfGyms, selectedCity) => {
    let indexName = selectedCity.toLowerCase() === "ncr"
        ? "NCRGyms"
        : selectedCity.charAt(0).toUpperCase() + selectedCity.slice(1) + "Gyms";

    console.log(`Processing Records for ${indexName} : `, Object.keys(listOfGyms).length);
    const gymListJson = Array.isArray(listOfGyms)
        ? listOfGyms
        : await listOfGyms.json();

    // Add `objectID` to each gym object using `gymName`
    const updatedGymList = gymListJson
    .map(({ 
        cardImage,
        facilities,
        classes,
        equipmentList,
        prices,
        ...gym 
    }) => ({
        objectID            :   gym.gymID,
        gymName             :   gym.gymName,
        gymDisplayName      :   gym.gymDisplayName,
        isWomensOnly        :   gym.isWomensOnly === true ? true : false,
        latitude            :   gym.latitude,
        longitude           :   gym.longitude,
        gymID               :   gym.gymID,
        rating              :   gym.rating,
        prices              :   {
            daily           :   prices.single.dailyPrice        || 0,
            monthly         :   prices.single.monthlyPrice      || 0,
            quarterly       :   prices.single.quarterlyPrice    || 0,
            halfYearly      :   prices.single.halfYearlyPrice   || 0,
            yearly          :   prices.single.yearlyPrice       || 0,
        },
        facilities          : Object.entries(facilities || {})
            .filter(([key, value]) => value === true) 
            .map(([key]) => key) || [], 
        equipmentList       : Object.entries(equipmentList || {})
            .filter(([key, value]) => value === true) 
            .map(([key]) => key) || [], 
        classes             : Object.entries(classes || {})
            .filter(([key, value]) => value === true) 
            .map(([key]) => key) || [], 
        locality            :   gym.locality,
        address             :   gym.address,
        pincode             :   gym.pincode,
        Portfolio           :   [gym.Portfolio?.[0]],
        city                :   gym.city,

    }));
    const response = await client.saveObjects({ indexName: indexName, objects: updatedGymList });
    console.log('Done : ', response);
    return;
};

addGymToAlgolia()
    .then(() => console.log('Successfully indexed objects!'))
    .catch((err) => console.error(err));