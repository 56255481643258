import React, { useEffect, useState, useTransition } from 'react';
import { Route, useLocation, Outlet, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dashboard from './Screens/B2BDashboard/Dashboard';
import GymFormOld from './Screens/B2BDashboard/GymForm';
import GymForm from './Screens/B2BDashboard/GymForm';
import GymCred from './Screens/B2BDashboard/GymCred';
import GymsDashboard from './Screens/B2BDashboard/GymsDashboard';

// Import Components
import BottomBar from "./components/BottomBar";
import { analytics } from "./config/firebase";
import { logEvent } from "firebase/analytics";
import './index.css'; // Import the global stylesheet
import BulkMessageSender from './Screens/B2BDashboard/DashBoardScenes/BulkMessageSender';
import TCPage from './Screens/TCPage';
import PPPage from './Screens/PPPage';
import StudioForm from './Screens/B2BDashboard/StudioForm';
import GymServicesAgreement from './Screens/TermsandConditions';
import Onboarding from './Screens/B2BDashboard/OnBoarding';
import SignUpWithTNC from './Screens/B2BDashboard/SignUpWithTNC';
import ReceiptsConfirmation from './Screens/ReceiptsConfirmation';

function App() {
    const location = useLocation();
    // const isHomePage = location.pathname === '/';
    const isGymsDashboard = location.pathname === '/GymsDashboard';
    const isTwilio = location.pathname === '/e36d873b8742b5f0c72a63e8ee85988f.html';
    
    // const [userCity, setUserCity] = useState(null);

    useEffect(() => {
        // Log an event when the user enters the app
        // Capture the user's browser type
        const currentTime = new Date().toISOString();
        const userAgent = navigator.userAgent;
        logEvent(analytics, `${userAgent}, ${currentTime}`);
    }, []);

    return (
        <div className="App">
            <ToastContainer bodyClassName="toastBody" progressClassName="toastProgress" />
            {/* <HtmlContent /> */}
            <Routes>
                <Route path="/Dashboard"          element={<Dashboard />} />
                <Route path="/GymFormOld"         element={<GymFormOld />} />
                <Route path="/GymForm"            element={<GymForm />} />
                <Route path="/StudioForm"         element={<StudioForm />} />
                <Route path="/GymCred"            element={<GymCred />} />
                <Route path="/GymCredOnboarding"  element={<Onboarding />} />
                <Route path="/SignUpWithTNC"      element={<SignUpWithTNC />} />
                <Route path="/"                   element={<GymsDashboard />} />
                <Route path="/BulkMessageSender"  element={<BulkMessageSender />} />
                <Route path="/TCPage"             element={<TCPage />} />
                <Route path="/PPPage"             element={<PPPage />} />
                <Route path='/TandCPage'          element={<GymServicesAgreement />}/>
                <Route path='/receipt/:receiptID' element={<ReceiptsConfirmation />} />
            </Routes>
            {/* Conditionally render the BottomBar */}
            {!isGymsDashboard && !isTwilio && <BottomBar />}
        </div>
    );
}

export default App;
