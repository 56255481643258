/**
 * UpdateReceipt.js
 * 
 * Helps to verify the receipt the user bought
 */

import { Timestamp, updateDoc } from "@firebase/firestore";

/**
 * Updates the receipt verified status to true.
 * 
 * @param {Object} receiptRef - Firestore reference to the receipt document.
 * @returns {Boolean} - Returns true if successful, false otherwise.
 */
export const updateReceipt = async (receiptRef) => {
    if (!receiptRef) {
        console.log("Invalid receipt reference provided.");
        return false;
    }

    try {
        await updateDoc(receiptRef, {
            verified: true,
            verifiedOn: Timestamp.now(), // Firestore will add this field if not present
        });

        return true;
    } catch (error) {
        console.error("Error in updating Receipt:", error);
        return false;
    }
};
