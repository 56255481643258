import { useEffect, useState } from "react";
import { formatEquipmentName } from "./GymFormHelpersExtended";


export const FirstSection = ({ 
    formData, 
    user, 
    handleChange, 
    goToNextSection, 
    handleMultiImagesUpload, 
    handleImageChange, 
    handleAddTimeSlot, 
    handleRemoveTimeSlot, 
    handleTimeSlotChange, 
    sameTimings, 
    handleSameTimingsChange, 
    globalTimeSlots, 
    handleGlobalTimeSlotChange,
    handleAddGlobalTimeSlot, 
    handleRemoveGlobalTimeSlot,
    handleUploadToFirestore, 
    uploading,
    selectedImages,
}) => {
    const [isFormValid, setIsFormValid] = useState(false);

    let fieldTextSize = 1;
    let fieldValueTextSize = 1;
    let sectionHeadingSize = 1.25;
    let spacing  = 1;

    useEffect(() => {
    const validateForm = () => {
        // Define required fields
        const requiredFields = ['gym', 'address', 'locality', 'city', 'country', 'pinCode', 'gymContactNo'];

        // Identify empty fields
        const emptyFields = requiredFields.filter((field) => formData[field]?.trim() === '');

        // Check if all required fields are filled
        const areFieldsFilled = emptyFields.length === 0;

        // Check if all time slots are filled for each day
        // const areTimeSlotsFilled = Object.values(formData.timeSlots).every(daySlots => 
        //     daySlots.every(slot => slot.openingTime !== '' && slot.closingTime !== '')
        // );

        setIsFormValid(areFieldsFilled);
    };

    validateForm();
}, [formData]);   

    return (
        <>
            <div className="mb-8">


                {/* Email */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Email
                    </label>
                    <input
                        type="text"
                        name="email"
                        value={user.email}
                        readOnly
                        className={`w-full px-2 py-1 bg-secondary border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    />
                </div>

                {/* Phone Number */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Contact Number
                    </label>
                    <input
                        type="tel"  // Corrected type
                        name="phoneNumber"
                        value={user.phoneNumber}  // Ensure user.phoneNumber is correctly initialized in state
                        readOnly
                        className={`w-full px-2 py-1 bg-secondary border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    />
                </div>


                <h2 className={`flex text-left text-lg lg:text-[${sectionHeadingSize}vw] font-semibold mt-10 mb-4`}>Basic Gym Information</h2>
                {/* Textbox 1 */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Name
                    </label>
                    <input
                        type="text"
                        name="gym"
                        value={formData.gym}
                        onChange={handleChange}
                        className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    />
                </div>

                {/* Textbox 2 */}
                <div className={`flex flex-row mb-4 lg:mb-[${spacing}vw] items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Address
                    </label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    />
                </div>

                {/* Textbox 3 */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Locality
                    </label>
                    <input
                        type="text"
                        name="locality"
                        value={formData.locality}
                        onChange={handleChange}
                        className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    />
                </div>
            
                {/* Textbox 4 */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        City
                    </label>
                    <select
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    >
                        <option value="">Select City</option> {/* Placeholder */}
                        <option value="Delhi">Delhi</option>
                        <option value="Noida">Noida</option>
                        <option value="Gurgaon">Gurgaon</option>
                        <option value="Ghaziabad">Ghaziabad</option>
                        <option value="test city">Test City</option>
                    </select>
                </div>

                {/* Textbox 5 */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Country
                    </label>
                    <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    />
                </div>

                {/* Textbox 6 */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Pin Code
                    </label>
                    <input
                        type="text"
                        name="pinCode"
                        value={formData.pinCode}
                        onChange={handleChange}
                        className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    />
                </div>

                {/* Textbox 7 */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        GST Number
                    </label>
                    <input
                        type="text"
                        name="gstin"
                        value={formData.gstin}
                        onChange={handleChange}
                        className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                    />
                </div>

                {/* Textbox 10 */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Display Contact Number
                    </label>
                    <input
                        type='tel'
                        name='gymContactNo'
                        value={formData.gymContactNo}
                        onChange={handleChange}
                        className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}/>
                </div>

                {/* Women's Only Gym Checkbox */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Is the gym only for women?
                    </label>
                    <input
                        type="checkbox"
                        name="isWomensOnly"
                        checked={formData.isWomensOnly || false} // Ensure a default boolean value
                        onChange={(e) => handleChange({ target: { name: 'isWomensOnly', value: e.target.checked } })}
                        className="w-5 h-5 border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                    />
                </div>

                {/* EMI Offer Checkbox */}
                <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Does the gym offer EMI options?
                    </label>
                    <input
                        type="checkbox"
                        name="offersEMI"
                        checked={formData.offersEMI || false} // Ensure a default boolean value
                        onChange={(e) => handleChange({ target: { name: 'offersEMI', value: e.target.checked } })}
                        className="w-5 h-5 border-gray-300 rounded-md focus:ring-primary focus:border-primary"
                    />
                </div>


                <div className="mb-8 mt-10">
                    <h2 className={`flex text-left text-lg lg:text-[${sectionHeadingSize}vw] font-semibold mb-4`}>Opening and Closing Times</h2>

                    <label className="flex items-center mb-4">
                        <input
                            type="checkbox"
                            checked={sameTimings}
                            onChange={(e) => handleSameTimingsChange(e.target.checked)}
                        />
                        <span className={`ml-2 text-md lg:text-[${fieldTextSize}vw] font-semibold text-primary`}>Same timings for all days?</span>
                    </label>

                    {sameTimings ? (
                        // Render global time slots
                        <div>
                            <h3 className={`text-left text-md lg:text-[${fieldTextSize}vw] mt-6 mb-2 font-semibold text-primary`}>All Days</h3>
                            {globalTimeSlots.map((slot, index) => (
                                <div key={index} className="mb-4 flex items-center">
                                    <label className={`w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>Opening Time</label>
                                    <input
                                        type="time"
                                        value={slot.openingTime}
                                        onChange={e => handleGlobalTimeSlotChange(index, 'openingTime', e.target.value)}
                                        className={`w-full px-2 py-1 border rounded-md text-md lg:text-[${fieldValueTextSize}vw]`}
                                    />

                                    <label className={`w-1/4 ml-4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>Closing Time</label>
                                    <input
                                        type="time"
                                        value={slot.closingTime}
                                        onChange={e => handleGlobalTimeSlotChange(index, 'closingTime', e.target.value)}
                                        className={`w-full px-2 py-1 border rounded-md text-md lg:text-[${fieldValueTextSize}vw]`}
                                    />

                                    {globalTimeSlots.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveGlobalTimeSlot(index)}
                                            className={`ml-4 px-2 bg-secondary text-tertiary lg:text-[${fieldValueTextSize}vw] font-semibold rounded-full`}
                                        >
                                            &times;
                                        </button>
                                    )}
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={handleAddGlobalTimeSlot}
                                className={`px-4 py-2 bg-secondary text-tertiary text-base lg:text-[${fieldTextSize}vw]  rounded-full hover:scale-105 hover:shadow-xl transition-all duration-500`}
                            >
                                + Add Time Slot for All Days
                            </button>
                        </div>
                    ) : (
                        // Render individual time slots for each day
                        Object.keys(formData.timeSlots).map(day => (
                            <div key={day}>
                                <h3 className="text-left text-md lg:text-xl mt-10 mb-2 font-semibold text-primary">{day}</h3>
                                {formData.timeSlots[day].map((slot, index) => (
                                    <div key={index} className="mb-4 flex items-center">
                                        <label className={`w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>Opening Time</label>
                                        <input
                                            type="time"
                                            value={slot.openingTime}
                                            onChange={e => handleTimeSlotChange(day, index, 'openingTime', e.target.value)}
                                            className="w-full px-2 py-1 border rounded-md text-md"
                                        />

                                        <label className={`w-1/4 ml-4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>Closing Time</label>
                                        <input
                                            type="time"
                                            value={slot.closingTime}
                                            onChange={e => handleTimeSlotChange(day, index, 'closingTime', e.target.value)}
                                            className="w-full px-2 py-1 border rounded-md text-md"
                                        />

                                        {formData.timeSlots[day].length > 1 && (
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveTimeSlot(day, index)}
                                                className="ml-4 px-2 bg-secondary text-tertiary text-[1.25em] font-semibold rounded-full"
                                            >
                                                &times;
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => handleAddTimeSlot(day)}
                                    className={`px-4 py-2 bg-secondary text-tertiary rounded-full hover:scale-105 hover:shadow-xl transition-all duration-500`}
                                >
                                    + Add Time Slot for {day}
                                </button>
                            </div>
                        ))
                    )}
                </div>

            </div>


            <div className={`mb-4 lg:mb-[${spacing}vw] flex lg:flex-row flex-col lg:items-center`}>
                <label className={`flex text-left lg:w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                    Upload Gym Logo
                </label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                />
            </div>

            <div className={`mb-4 lg:mb-[${spacing}vw] flex flex-col mt-4`}>
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className={`flex text-left lg:w-1/5 lg:text-[${fieldTextSize}vw] font-normal mb-2`}>
                        Upload Gym Images
                    </label>
                    <input type="file" multiple onChange={handleMultiImagesUpload} accept="image/*" />
                    <button 
                        type='button' 
                        onClick={handleUploadToFirestore} 
                        disabled={uploading} 
                        className="mt-4 lg:mt-0 px-6 py-2 rounded-full bg-secondary text-white"
                    >
                        {uploading ? "Uploading..." : "Upload Images"}
                    </button>
                </div>
            </div>

            {/* Navigation buttons */}
            <div className="flex justify-center mt-10">
                <button 
                    type='button'
                    onClick={goToNextSection} 
                    disabled={!isFormValid}
                    className={`px-4 py-2 bg-secondary w-28 rounded-full text-tertiary hover:bg-primary hover:scale-110 transition-all duration-300 ${isFormValid ? '' : 'opacity-50'}`}>
                    Next &gt;
                </button>
            </div>
        </>
    )
}

// For the Gym owner to Select between the two models
export const SlideOne = ({formData, selectedOffers, pricingType, currentSlide, setCurrentSlide, handlePricingTypeChange}) => {
    const [isMobile] = useState(window.innerWidth <= 648)

    return (
        <div className='flex justify-center items-center flex-col lg:flex-row w-full'>
            <div className="lg:mb-4 lg:mt-0 flex flex-col lg:flex-row justify-center items-center lg:gap-20 gap-10">
                {/* Commission Pricing Radio Button */}
                <div className="flex items-center">
                    <input
                        id="commission"
                        type="radio"
                        name="pricingType"
                        value="commission"
                        checked={formData.paymentModel === 'commission'}
                        onChange={handlePricingTypeChange}
                        className="mr-2"
                    />
                    <label htmlFor="commission" className="text-md md:text-[1.3vw] font-semibold text-primary cursor-pointer">
                        Commission Based
                    </label>
                    <span className="ml-2 relative group">
                        <span className="text-tertiary py-1 px-2 rounded-full bg-secondary hover:bg-primary cursor-pointer">?</span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block -top-14 -right-8 lg:left-7 w-64 p-2 bg-secondary text-white text-sm rounded-md shadow-lg transition-opacity duration-300">
                            20% commission deducts a fee from the membership fee.
                        </div>
                    </span>
                </div>

                {/* Exclusive Pricing Radio Button */}
                <div className="flex items-center">
                    <input
                        id="exclusive"
                        type="radio"
                        name="pricingType"
                        value="exclusive"
                        checked={formData.paymentModel === 'exclusive'}
                        onChange={handlePricingTypeChange}
                        className="mr-2"
                    />
                    <label htmlFor="exclusive" className="text-md md:text-[1.3vw] font-semibold text-primary cursor-pointer">
                        Exclusive Pricing
                    </label>
                    <span className="ml-2 relative group">
                        <span className="text-tertiary py-1 px-2 rounded-full bg-secondary hover:bg-primary cursor-pointer">?</span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block -top-14 -right-8 lg:left-7 w-64 p-2 bg-secondary text-white text-sm rounded-md shadow-lg transition-opacity duration-300">
                            Exclusive pricing allows you to set your own prices.
                        </div>
                    </span>
                </div>
            </div>

            <button
                type='button'
                onClick={() => setCurrentSlide(2)}
                disabled={currentSlide === 1 && !pricingType} // Ensure pricingType is selected for slide 1
                className={`absolute lg:right-10 bottom-2 right-2 lg:top-[14vh] px-4 lg:px-[1vw] ${isMobile ? 'py-3' : 'py-[1vw]' } lg:h-[4vw]  bg-secondary text-tertiary text-base lg:text-[1vw] rounded-full hover:bg-primary scale-75 md:scale-90 lg:scale-100 hover:scale-110 transition-all duration-300 ${(currentSlide === 1 && !pricingType) || (currentSlide === 2 && !Object.values(selectedOffers).some(Boolean)) ? 'opacity-50 cursor-not-allowed' : ''}`}>
                {isMobile ? '>' : 'Next'}
            </button>
        </div>
    )
}


export const BankDetails = ({ formData, handleChange }) => {
    // Assuming formData has accountCategory to track whether personal or merchant is selected
    const isPersonal = formData.accountCategory === 'personal';

    let fieldTextSize = 1;
    let fieldValueTextSize = 1;
    let sectionHeadingSize = 1.25;
    let spacing  = 1;

    return (
        <>
            <h2 className={`flex text-left text-xl lg:text-[${sectionHeadingSize}vw] font-semibold mb-4`}>Bank Details</h2>

            {/* Account Name */}
            <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                    Account Name
                </label>
                <input
                    type="text"
                    name="accountName"
                    value={formData.accountName}
                    onChange={handleChange}
                    className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                />
            </div>

            {/* Radio buttons for Personal or Merchant */}
            <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                    Account Category
                </label>
                <div className="w-full flex gap-4">
                    <label className="flex items-center text-sm md:text-base lg:text-[0.9vw]">
                        <input
                            type="radio"
                            name="accountCategory"
                            value="personal"
                            checked={formData.accountCategory === 'personal'}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        Personal
                    </label>
                    <label className="flex items-center text-sm md:text-base lg:text-[0.9vw]">
                        <input
                            type="radio"
                            name="accountCategory"
                            value="merchant"
                            checked={formData.accountCategory === 'merchant'}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        Merchant
                    </label>
                </div>
            </div>

            {/* Account Number */}
            <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                    Account Number
                </label>
                <input
                    type="text"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleChange}
                    className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                />
            </div>

            {/* Conditional fields: Account Type and IFSC Code, only visible if 'Personal' is selected */}
            {isPersonal && (
                <>
                    {/* Account Type */}
                    <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                    <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                        Account Type
                    </label>
                    <select
                        name="accountType"
                        value={formData.accountType}
                        onChange={handleChange}
                        className={`w-full px-2 py-[0.2vw] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-sm md:text-base lg:text-[${fieldValueTextSize}vw]`}
                    >
                        <option value="" disabled>Select Account Type</option>
                        <option value="Saving">Saving</option>
                        <option value="Current">Current</option>
                    </select>
                    </div>

                    {/* IFSC Code */}
                    <div className={`mb-4 lg:mb-[${spacing}vw] flex items-center`}>
                        <label className={`flex text-left w-1/4 text-sm lg:text-[${fieldTextSize}vw] font-medium`}>
                            IFSC Code
                        </label>
                        <input
                            type="text"
                            name="ifscCode"
                            value={formData.ifscCode}
                            onChange={handleChange}
                            className={`w-full px-2 py-[0.2vw] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-sm md:text-base lg:text-[${fieldValueTextSize}vw]`}
                        />
                    </div>
                </>
            )}
        </>
    );
};

/**
 * SlideTwo component allows gym owners to select which offers (Single, Couple, Family) they provide.
 * This component includes navigation buttons and responsive design to handle both small and large screens.
 * 
 * @param {object} selectedOffers - Contains the selected offers (Single, Couple, Family).
 * @param {number} currentSlide - Indicates the current slide in the flow.
 * @param {function} setCurrentSlide - Function to update the current slide.
 * @param {string} pricingType - Selected pricing type (commission or exclusive).
 * @param {function} handleOfferChange - Function to handle changes in selected offers.
 */
export const SlideTwo = ({ selectedOffers, currentSlide, setCurrentSlide, pricingType, handleOfferChange }) => {
    const [isMobile] = useState(window.innerWidth <= 648)

    return (
        <div className='flex flex-col lg:flex-row justify-between items-center gap-10 lg:gap-32'>
            {/* Previous Slide Button */}
            <button
                type='button'
                onClick={() => setCurrentSlide(1)}
                className={`absolute left-0 md:left-4 lg:left-10 top-[14vh] px-4 lg:px-[1vw] ${isMobile ? 'py-3' : 'py-[1.2vw] hover:scale-110 hover:bg-primary' }  bg-secondary text-tertiary text-base lg:text-[0.9vw] rounded-full scale-75 md:scale-90 lg:scale-100 transition-all duration-300`}>
                {isMobile ? '<' : 'Prev'}
            </button>

            {/* Offers Selection */}
            <div className='flex flex-col lg:flex-row gap-10 lg:gap-[16vw] items-center text-primary'>
                <h2 className="text-xl md:text-2xl lg:text-[1.7vw] text-center font-bold mt-4">What offers do you have?</h2>

                <div className="mt-6 lg:mt-4 flex-1 scale-125 flex flex-col gap-5">
                    {/* Single Offer */}
                    <div className={`font-medium text-sm md:text-base lg:text-[1vw] w-fit`}>
                        <label className="ml-2 relative group flex items-center">
                            <input
                                type="checkbox"
                                checked={selectedOffers.single}
                                onChange={() => handleOfferChange('single')}
                                className="mr-2"
                            />
                            <span>Single</span>
                            <span className="ml-4 text-tertiary text-xs py-1 px-2 rounded-full bg-secondary hover:bg-primary cursor-pointer">?</span>
                            {/* Tooltip */}
                            <div className="absolute hidden group-hover:block -top-12 lg:-top-14 left-10 lg:left-28 w-52 lg:w-[12vw] p-2 scale-[60%] md:scale-75 lg:scale-100 bg-secondary text-white text-sm lg:text-[0.8vw] z-50 rounded-md shadow-lg transition-opacity duration-300">
                                10% commission deducts a fee from the membership fee.
                            </div>
                        </label>
                    </div>

                    {/* Couple Offer */}
                    <div className={`font-medium text-sm md:text-base lg:text-[1vw] w-fit`}>
                        <label className="ml-2 relative group flex items-center">
                            <input
                                type="checkbox"
                                checked={selectedOffers.couple}
                                onChange={() => handleOfferChange('couple')}
                                className="mr-2"
                            />
                            <span>Couple</span>
                            <span className="ml-2 text-tertiary text-xs py-1 px-2 rounded-full bg-secondary hover:bg-primary cursor-pointer">?</span>
                            {/* Tooltip */}
                            <div className="absolute hidden group-hover:block -top-12 lg:-top-14 left-10 lg:left-28 w-52 lg:w-[12vw] p-2 scale-[60%] md:scale-75 lg:scale-100 bg-secondary text-white text-sm lg:text-[0.8vw] z-50 rounded-md shadow-lg transition-opacity duration-300">
                                10% commission deducts a fee from the membership fee.
                            </div>
                        </label>
                    </div>

                    {/* Family Offer */}
                    <div className={`font-medium text-sm md:text-base lg:text-[1vw] w-fit`}>
                        <label className="ml-2 relative group flex items-center">
                            <input
                                type="checkbox"
                                checked={selectedOffers.family}
                                onChange={() => handleOfferChange('family')}
                                className="mr-2"
                            />
                            <span>Family</span>
                            <span className="ml-3 text-tertiary text-xs py-1 px-2 rounded-full bg-secondary hover:bg-primary cursor-pointer">?</span>
                            {/* Tooltip */}
                            <div className="absolute hidden group-hover:block -top-12 lg:-top-14 left-10 lg:left-28 w-52 lg:w-[12vw] p-2 scale-[60%] md:scale-75 lg:scale-100 bg-secondary text-white text-sm lg:text-[0.8vw] z-50 rounded-md shadow-lg transition-opacity duration-300">
                                10% commission deducts a fee from the membership fee.
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            {/* Next Slide Button */}
            <button
                type='button'
                onClick={() => {
                    if (selectedOffers.single) {
                        setCurrentSlide(3);
                    } else if (selectedOffers.couple) {
                        setCurrentSlide(4);
                    } else {
                        setCurrentSlide(5);
                    }
                }}
                disabled={(currentSlide === 2 && !Object.values(selectedOffers).some(Boolean))} // Ensure at least one offer is selected
                className={`absolute right-1 md:right-4 lg:right-10 top-[14vh] px-4 lg:px-[1vw] ${isMobile ? 'py-3' : 'py-[1.3vw] hover:bg-primary hover:scale-110' }  bg-secondary text-tertiary text-base lg:text-[1vw] rounded-full scale-75 md:scale-90 lg:scale-100 transition-all duration-300 ${((currentSlide === 1 && !pricingType) || (currentSlide === 2 && !Object.values(selectedOffers).some(Boolean))) ? 'opacity-50 cursor-not-allowed' : ''}`}>
                {isMobile ? '>' : 'Next'}
            </button>
        </div>
    );
};

export const Commission = ({ userType, formData, handlePriceChange, currentSlide, setCurrentSlide, selectedOffers }) => {

    const isMRPFilled = Object.values({
        dailyPrice        : parseFloat(formData.prices[userType].dailyPrice),
        monthlyPrice      : parseFloat(formData.prices[userType].monthlyPrice),
        quarterlyPrice    : parseFloat(formData.prices[userType].quarterlyPrice),
        halfYearlyPrice   : parseFloat(formData.prices[userType].halfYearlyPrice),
        yearlyPrice       : parseFloat(formData.prices[userType].yearlyPrice)
    }).some(value => value > 0);

    const handleNext = () => {
        const singlePrices = formData.prices.single;
        const couplePrices = formData.prices.couple;
        const familyPrices = formData.prices.family;
    
        // Validate the price hierarchy
        const isValid = [
            { single: parseFloat(singlePrices.dailyPrice)     , couple: parseFloat(couplePrices.dailyPrice)     , family: parseFloat(familyPrices.dailyPrice) },
            { single: parseFloat(singlePrices.monthlyPrice)   , couple: parseFloat(couplePrices.monthlyPrice)   , family: parseFloat(familyPrices.monthlyPrice) },
            { single: parseFloat(singlePrices.quarterlyPrice) , couple: parseFloat(couplePrices.quarterlyPrice) , family: parseFloat(familyPrices.quarterlyPrice) },
            { single: parseFloat(singlePrices.halfYearlyPrice), couple: parseFloat(couplePrices.halfYearlyPrice), family: parseFloat(familyPrices.halfYearlyPrice) },
            { single: parseFloat(singlePrices.yearlyPrice)    , couple: parseFloat(couplePrices.yearlyPrice)    , family: parseFloat(familyPrices.yearlyPrice) },
        ].every(({ single, couple, family }) => {
            return (
                (!single || !couple || single > couple) && (!couple || !family || couple > family)
            );
        });
    
        if (isValid) {
            // Proceed to the next slide if validation passes
            if (currentSlide === 3 && selectedOffers.couple) {
                setCurrentSlide(4);
            } else if (currentSlide === 3 && selectedOffers.family) {
                setCurrentSlide(5);
            } else if (currentSlide === 4 && selectedOffers.family) {
                setCurrentSlide(5);
            } else if ((currentSlide === 3 && !selectedOffers.couple && !selectedOffers.family) || (currentSlide === 4 && !selectedOffers.family) || currentSlide === 5) {
                setCurrentSlide(6);
            }
        } else {
            // Optionally, you could show an alert or error message if validation fails
            alert("Please ensure that the pricing hierarchy is maintained: Single's price > Couple's price > Family's price.");
        }
    };

    return (
        <div className="flex justify-between gap-80 items-center">
            {/* Prev Button */}
            <button
                type="button"
                onClick={() => {
                    if (currentSlide === 3) {
                                                        setCurrentSlide(2);
                    } else if (currentSlide === 4) {
                        if      (selectedOffers.single) setCurrentSlide(3);
                        else                            setCurrentSlide(2);
                    } else if (currentSlide === 5) {
                        if      (selectedOffers.couple) setCurrentSlide(4);
                        else if (selectedOffers.single) setCurrentSlide(3);
                        else                            setCurrentSlide(2);
                    } else if (currentSlide === 6) {
                        if      (selectedOffers.family) setCurrentSlide(5);
                        else if (selectedOffers.couple) setCurrentSlide(4);
                        else if (selectedOffers.single) setCurrentSlide(3);
                        else                            setCurrentSlide(2);
                    }
                }}
                className={`absolute left-10 top-[14vh] px-4 py-5 bg-secondary text-tertiary rounded-full hover:bg-primary scale-75 md:scale-90 lg:scale-100 hover:scale-110 transition-all duration-300`}>
                Prev
            </button>

            <div className='flex gap-24 -ml-40'>
                <div className="text-primary text-2xl font-semibold -ml-10 -mt-4">{currentSlide === 3 ? 'Prices for Singles' : currentSlide === 4 ? 'Prices for Couples' : 'Prices for Family'}</div>

                <div className="flex flex-col gap-[18px] font-semibold text-lg text-primary mt-5 w-36">
                    <div></div>
                    <div>Single Day</div>
                    <div>1 Month</div>
                    <div>3 Months</div>
                    <div>6 Months</div>
                    <div>12 Months</div>
                </div>
                <div className="flex flex-col gap-3 ">
                    <div className="text-center font-semibold text-primary">MRP</div>
                    <input
                        type="number"
                        name={`prices.${userType}.dailyPrice`} // Fixed interpolation
                        value={formData.prices[userType].dailyPrice}
                        onChange={handlePriceChange}
                        className="px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md text-center"
                    />
                    <input
                        type="number"
                        name={`prices.${userType}.monthlyPrice`} // Fixed interpolation
                        value={formData.prices[userType].monthlyPrice}
                        onChange={handlePriceChange}
                        className="px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md text-center"
                    />
                    <input
                        type="number"
                        name={`prices.${userType}.quarterlyPrice`} // Fixed interpolation
                        value={formData.prices[userType].quarterlyPrice}
                        onChange={handlePriceChange}
                        className="px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md text-center"
                    />
                    <input
                        type="number"
                        name={`prices.${userType}.halfYearlyPrice`} // Fixed interpolation
                        value={formData.prices[userType].halfYearlyPrice}
                        onChange={handlePriceChange}
                        className="px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md text-center"
                    />
                    <input
                        type="number"
                        name={`prices.${userType}.yearlyPrice`} // Fixed interpolation
                        value={formData.prices[userType].yearlyPrice}
                        onChange={handlePriceChange}
                        className="px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md text-center"
                    />
                </div>
            </div>

            {/* Next Button */}
            <button
                type="button"
                onClick={handleNext}
                disabled={!isMRPFilled}
                className={`absolute right-1 md:right-4 lg:right-10 top-[14vh] px-4 py-5 bg-secondary text-tertiary rounded-full hover:bg-primary scale-75 md:scale-90 lg:scale-100 hover:scale-110 transition-all duration-300 ${isMRPFilled ? '' : 'opacity-50 cursor-not-allowed'} ${currentSlide === 5 ? 'hidden' : ''}`}
            >
                Next
            </button>
        </div>
    );
}

export const Exclusive = ({ userType, formData, handlePriceChange, currentSlide, setCurrentSlide, selectedOffers }) => {
    const [isMobile] = useState(window.innerWidth <= 648)

    // Check if at least one MRP and one Exclusive field are filled
    // const isMRPFilled = Object.values({
    //     dailyPrice      : parseFloat(formData.prices[userType].dailyPrice),
    //     monthlyPrice    : parseFloat(formData.prices[userType].monthlyPrice),
    //     quarterlyPrice  : parseFloat(formData.prices[userType].quarterlyPrice),
    //     halfYearlyPrice : parseFloat(formData.prices[userType].halfYearlyPrice),
    //     yearlyPrice     : parseFloat(formData.prices[userType].yearlyPrice)
    // }).some(value => value > 0);

    // const isExclusiveFilled = Object.values({
    //     exclusiveDailyPrice      : parseFloat(formData.prices[userType].exclusiveDailyPrice),
    //     exclusiveMonthlyPrice    : parseFloat(formData.prices[userType].exclusiveMonthlyPrice),
    //     exclusiveQuarterlyPrice  : parseFloat(formData.prices[userType].exclusiveQuarterlyPrice),
    //     exclusiveHalfYearlyPrice : parseFloat(formData.prices[userType].exclusiveHalfYearlyPrice),
    //     exclusiveYearlyPrice     : parseFloat(formData.prices[userType].exclusiveYearlyPrice)
    // }).some(value => value > 0);

    // const[showAlert, setShowAlert] = useState();

    // // Function to validate that Singles > Couples > Family prices
    // const isPriceHierarchyValid = () => {
    //     const singlePrices = formData.prices.single;
    //     const couplePrices = formData.prices.couple;
    //     const familyPrices = formData.prices.family;

    //     // Compare MRP and Exclusive prices for Singles > Couples > Family
    //     const isValid = [
    //         { single: parseFloat(singlePrices.dailyPrice),               couple: parseFloat(couplePrices.dailyPrice),               family: parseFloat(familyPrices.dailyPrice) },
    //         { single: parseFloat(singlePrices.monthlyPrice),             couple: parseFloat(couplePrices.monthlyPrice),             family: parseFloat(familyPrices.monthlyPrice) },
    //         { single: parseFloat(singlePrices.quarterlyPrice),           couple: parseFloat(couplePrices.quarterlyPrice),           family: parseFloat(familyPrices.quarterlyPrice) },
    //         { single: parseFloat(singlePrices.halfYearlyPrice),          couple: parseFloat(couplePrices.halfYearlyPrice),          family: parseFloat(familyPrices.halfYearlyPrice) },
    //         { single: parseFloat(singlePrices.yearlyPrice),              couple: parseFloat(couplePrices.yearlyPrice),              family: parseFloat(familyPrices.yearlyPrice) },
    //         { single: parseFloat(singlePrices.exclusiveDailyPrice),      couple: parseFloat(couplePrices.exclusiveDailyPrice),      family: parseFloat(familyPrices.exclusiveDailyPrice) },
    //         { single: parseFloat(singlePrices.exclusiveMonthlyPrice),    couple: parseFloat(couplePrices.exclusiveMonthlyPrice),    family: parseFloat(familyPrices.exclusiveMonthlyPrice) },
    //         { single: parseFloat(singlePrices.exclusiveQuarterlyPrice),  couple: parseFloat(couplePrices.exclusiveQuarterlyPrice),  family: parseFloat(familyPrices.exclusiveQuarterlyPrice) },
    //         { single: parseFloat(singlePrices.exclusiveHalfYearlyPrice), couple: parseFloat(couplePrices.exclusiveHalfYearlyPrice), family: parseFloat(familyPrices.exclusiveHalfYearlyPrice) },
    //         { single: parseFloat(singlePrices.exclusiveYearlyPrice),     couple: parseFloat(couplePrices.exclusiveYearlyPrice),     family: parseFloat(familyPrices.exclusiveYearlyPrice) }
    //     ].every(({ single, couple, family }) => {
    //         return (
    //             // Only compare if all prices (single, couple, family) exist
    //             (!single || !couple || (single >= couple)) &&
    //             (!couple || !family || (couple >= family)) &&
    //             (!single || !family || (single >= family))
    //         );
    //     });
    
    //     return isValid;
    // };

    // // Function to get border color based on MRP and Exclusive price validation for the same plan
    // const getInputBorder = (mrpFieldName, exclusiveFieldName) => {
    //     const mrp       = formData.prices[userType][mrpFieldName];
    //     const exclusive = formData.prices[userType][exclusiveFieldName];

    //     // If either MRP or Exclusive is filled but the other is empty, apply a red border
    //     if ((mrp && !exclusive) || (!mrp && exclusive)) {
    //         return {
    //             mrpBorder      : !mrp       ? 'border-red' : 'border-gray-300', // Red border if MRP is empty
    //             exclusiveBorder: !exclusive ? 'border-red' : 'border-gray-300'  // Red border if Exclusive is empty
    //         };
    //     }

    //     return {
    //         mrpBorder      : 'border-gray-300',        // Default border if both are filled or empty
    //         exclusiveBorder: 'border-gray-300'         // Default border if both are filled or empty
    //     };
    // };

    // Handle Next Button Click
    const handleNext = () => {
        // const {          dailyPrice,          monthlyPrice,          quarterlyPrice,          halfYearlyPrice,          yearlyPrice } = formData.prices[userType];
        // const { exclusiveDailyPrice, exclusiveMonthlyPrice, exclusiveQuarterlyPrice, exclusiveHalfYearlyPrice, exclusiveYearlyPrice } = formData.prices[userType];
    
        // // Convert strings to numbers before comparing
        // const priceValidation = [
        //     { mrp: parseFloat(dailyPrice)     , exclusive: parseFloat(exclusiveDailyPrice)     , plan: "Single Day" },
        //     { mrp: parseFloat(monthlyPrice)   , exclusive: parseFloat(exclusiveMonthlyPrice)   , plan: "1 Month" },
        //     { mrp: parseFloat(quarterlyPrice) , exclusive: parseFloat(exclusiveQuarterlyPrice) , plan: "3 Months" },
        //     { mrp: parseFloat(halfYearlyPrice), exclusive: parseFloat(exclusiveHalfYearlyPrice), plan: "6 Months" },
        //     { mrp: parseFloat(yearlyPrice)    , exclusive: parseFloat(exclusiveYearlyPrice)    , plan: "12 Months" }
        // ];
    
        // // Validate MRP and Exclusive Prices
        // for (const { mrp, exclusive, plan } of priceValidation) {
        //     if (mrp && exclusive) {
        //         if (exclusive >= mrp) {
        //             setShowAlert(true);
        //             return;
        //         }
        //     } else if (mrp || exclusive) {
        //         return;  // Prevent advancing if MRP is missing while Exclusive is present
        //     }
        // }
    
        // // **New Validation**: Check price hierarchy before advancing
        // if (isPriceHierarchyValid() === false) {
        //     alert("Price hierarchy invalid! Singles' prices must be greater than Couples' and Couples' prices must be greater than Family.");
        //     return ;
        // }
    
        // Proceed to the next slide if all validations pass
        if (currentSlide === 3 && selectedOffers.couple) {
            setCurrentSlide(4);
        } else if (currentSlide === 3 && selectedOffers.family) {
            setCurrentSlide(5);
        } else if (currentSlide === 4 && selectedOffers.family) {
            setCurrentSlide(5);
        } else if ((currentSlide === 3 && !selectedOffers.couple && !selectedOffers.family) || (currentSlide === 4 && !selectedOffers.family) || currentSlide === 5) {
            setCurrentSlide(6);
        }
    };

    // useEffect(() => {
    //     setShowAlert(false);
    // }, [currentSlide]);

    return (
        <div className="flex justify-cente gap-36 items-center">
        {/* Left Button for Slide */}
        <button
            type="button"
            onClick={() => {
                if (currentSlide === 3) {
                                                    setCurrentSlide(2);
                } else if (currentSlide === 4) {
                    if      (selectedOffers.single) setCurrentSlide(3);
                    else                            setCurrentSlide(2);
                } else if (currentSlide === 5) {
                    if      (selectedOffers.couple) setCurrentSlide(4);
                    else if (selectedOffers.single) setCurrentSlide(3);
                    else                            setCurrentSlide(2);
                } else if (currentSlide === 6) {
                    if      (selectedOffers.family) setCurrentSlide(5);
                    else if (selectedOffers.couple) setCurrentSlide(4);
                    else if (selectedOffers.single) setCurrentSlide(3);
                    else                            setCurrentSlide(2);
                }
            }}
            // className={`absolute left-0 md:left-4 lg:left-10 top-[14vh] px-4 lg:px-[1vw] ${isMobile ? 'py-3' : 'py-[1.3vw] hover:scale-110 hover:bg-primary' } bg-secondary text-tertiary text-base lg:text-[1vw] rounded-full  scale-75 md:scale-90 lg:scale-100 transition-all duration-300 ${!isPriceHierarchyValid() ? 'bg-gray-300 cursor-not-allowed' : 'bg-secondary text-tertiary'} `}
            className={`absolute left-0 md:left-4 lg:left-10 top-[14vh] px-4 lg:px-[1vw] ${isMobile ? 'py-3' : 'py-[1.3vw] hover:scale-110 hover:bg-primary' } bg-secondary text-tertiary text-base lg:text-[1vw] rounded-full  scale-75 md:scale-90 lg:scale-100 transition-all duration-300`}
            // disabled={!isPriceHierarchyValid()}
        >
            {isMobile ? "<" : "Prev"}
        </button>

        {/* Prices Input */}
        <div className="flex justify-center gap-6 md:gap-10 mt-[1em] pt-0">
            <div className="absolute top-6 lg:top-[12%] left-10 lg:left-[8%] text-primary text-lg md:text-xl lg:text-[1.25vw] font-semibold">
                {currentSlide === 3 ? 'Prices for Singles' : currentSlide === 4 ? 'Prices for Couples' : 'Prices for Family'}
            </div>

            {/* Plan Names */}
            <div className="flex flex-col gap-3 lg:gap-[1.1vw] items-center text-center mt-6 lg:mt-[2.5vw] -ml-0 md:-ml-10 text-primary">
                <div className="text-sm md:text-base lg:text-[1vw] font-semibold w-[18vw] md:w-28 lg:w-32">Single Day</div>
                <div className="text-sm md:text-base lg:text-[1vw] font-semibold w-[18vw] md:w-28 lg:w-32">1 Month</div>
                <div className="text-sm md:text-base lg:text-[1vw] font-semibold w-[18vw] md:w-28 lg:w-32">3 Months</div>
                <div className="text-sm md:text-base lg:text-[1vw] font-semibold w-[18vw] md:w-28 lg:w-32">6 Months</div>
                <div className="text-sm md:text-base lg:text-[1vw] font-semibold w-[18vw] md:w-28 lg:w-32">12 Months</div>
            </div>

            {/* MRP Fields */}
            <div className="flex flex-col gap-[0.75vw] items-center">
                <div className="text-sm md:text-base lg:text-[1vw] text-center font-semibold text-primary">MRP</div>
                {[
                    { mrpField: 'dailyPrice',      exclusiveField: 'exclusiveDailyPrice'      },
                    { mrpField: 'monthlyPrice',    exclusiveField: 'exclusiveMonthlyPrice'    },
                    { mrpField: 'quarterlyPrice',  exclusiveField: 'exclusiveQuarterlyPrice'  },
                    { mrpField: 'halfYearlyPrice', exclusiveField: 'exclusiveHalfYearlyPrice' },
                    { mrpField: 'yearlyPrice',     exclusiveField: 'exclusiveYearlyPrice'     }
                ].map(({ mrpField, exclusiveField }) => {
                    // const { mrpBorder, exclusiveBorder } = getInputBorder(mrpField, exclusiveField);
                    return (
                        <input
                            key={mrpField}
                            type="number"
                            name={`prices.${userType}.${mrpField}`}
                            value={formData.prices[userType][mrpField]}
                            onChange={handlePriceChange}
                            className={`px-2 py-[2.6px] md:py-[2px] lg:py-[0.25vw] border rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-sm lg:text-[0.85vw] max-w-20 md:max-w-28 lg:max-w-full text-center`}
                        />
                    );
                })}
            </div>

            {/* Exclusive Fields */}
            <div className="flex flex-col gap-[0.75vw] items-center">
                <div className="text-sm md:text-base lg:text-[1vw] text-center font-semibold text-primary">Exclusive</div>
                {[
                    { mrpField: 'dailyPrice',      exclusiveField: 'exclusiveDailyPrice'      },
                    { mrpField: 'monthlyPrice',    exclusiveField: 'exclusiveMonthlyPrice'    },
                    { mrpField: 'quarterlyPrice',  exclusiveField: 'exclusiveQuarterlyPrice'  },
                    { mrpField: 'halfYearlyPrice', exclusiveField: 'exclusiveHalfYearlyPrice' },
                    { mrpField: 'yearlyPrice',     exclusiveField: 'exclusiveYearlyPrice'     }
                ].map(({ mrpField, exclusiveField }) => {
                    // const { mrpBorder, exclusiveBorder } = getInputBorder(mrpField, exclusiveField);
                    return (
                        <input
                            key={exclusiveField}
                            type="number"
                            name={`prices.${userType}.${exclusiveField}`}
                            value={formData.prices[userType][exclusiveField]}
                            onChange={handlePriceChange}
                            className={`px-2 py-[2.6px] md:py-[2px] lg:py-[0.25vw] border rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-sm md:text-[0.85vw] max-w-20 md:max-w-28 lg:max-w-full text-center `}
                        />
                    );
                })}
            </div>
            {/* {showAlert && <div className="text-center absolute top-[17vh] right-40 font-semibold text-sm lg:text-[1vw] text-red">Exlusive Price <br />should be less than the MRP</div>} */}
        </div>

        {/* Next Button */}
        <button
            type="button"
            onClick={handleNext}
            // disabled={!(isMRPFilled && isExclusiveFilled)}
            // className={`absolute right-0 md:right-4 lg:right-10 top-[14vh] px-4 lg:px-[1vw] ${isMobile ? 'py-3' : 'py-[1.3vw] hover:scale-110' } text-base lg:text-[1vw] rounded-full transition-all duration-300 md:scale-90 lg:scale-100 ${!(isMRPFilled && isExclusiveFilled) ? 'bg-gray-300 cursor-not-allowed' : 'bg-secondary text-tertiary hover:bg-primary scale-75'}`}
            className={`absolute right-0 md:right-4 lg:right-10 top-[14vh] px-4 lg:px-[1vw] ${isMobile ? 'py-3' : 'py-[1.3vw] hover:scale-110' } text-base lg:text-[1vw] rounded-full transition-all duration-300 md:scale-90 lg:scale-100 bg-secondary text-tertiary hover:bg-primary scale-75`}
        >
            {isMobile ? '>' : 'Next'}
        </button>
        </div>
    );
};

export const ReviewCommissionPrices = ({ formData, selectedOffers, setCurrentSlide }) => {
    return (
        <>  
            <button
                type='button'
                onClick={() => {
                    if      (selectedOffers.family) setCurrentSlide(5);
                    else if (selectedOffers.couple) setCurrentSlide(4);
                    else if (selectedOffers.single) setCurrentSlide(3);
                }}
                className="absolute left-10 top-[14vh] px-4 py-5 bg-secondary text-tertiary rounded-full hover:bg-primary hover:scale-110 transition-all duration-300"
            >
                Prev
            </button>

            <div className="overflow-x-auto mt-10">
                <table className="table-auto w-full">
                    <thead>
                        <tr>
                            <th className="text-xl px-6 py-2">Plan</th>
                            <th className="text-xl px-6 py-2">Single (MRP)</th>
                            <th className="text-xl px-6 py-2">Couple (MRP)</th>
                            <th className="text-xl px-6 py-2">Family (MRP)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[
                            { label: "Single Day", mrpField: "dailyPrice" },
                            { label: "1 Month", mrpField: "monthlyPrice" },
                            { label: "3 Months", mrpField: "quarterlyPrice" },
                            { label: "6 Months", mrpField: "halfYearlyPrice" },
                            { label: "12 Months", mrpField: "yearlyPrice" },
                        ].map(({ label, mrpField }) => (
                            <tr key={label}>
                                <td className="px-6 py-2 text-center font-semibold">{label}</td>

                                {/* Single Prices */}
                                <td className="px-6 py-2 text-center">{formData.prices.single[mrpField] || "-"}</td>

                                {/* Couple Prices */}
                                <td className="px-6 py-2 text-center">{formData.prices.couple[mrpField] || "-"}</td>

                                {/* Family Prices */}
                                <td className="px-6 py-2 text-center">{formData.prices.family[mrpField] || "-"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export const ReviewExclusivePrices = ({ formData, selectedOffers, setCurrentSlide}) => {
    const [isMobile] = useState(window.innerWidth <= 768)

    return (
        <div className={`w-full ${isMobile ? 'overflow-x-scroll' : '' } flex items-center justify-center` }>
            <button
                onClick={() => {
                    if      (selectedOffers.family) setCurrentSlide(5);
                    else if (selectedOffers.couple) setCurrentSlide(4);
                    else if (selectedOffers.single) setCurrentSlide(3);
                }}
                className={`absolute left-1 md:left-4 lg:left-10 top-[14vh] px-4 lg:px-[1vw] ${isMobile ? 'py-3' : 'py-[1.3vw] hover:bg-primary hover:scale-110' }  bg-secondary text-tertiary text-base lg:text-[1vw] rounded-full scale-75 md:scale-90 lg:scale-100 transition-all duration-300`}
            >
                {isMobile ? '<' : 'Prev'}
            </button>


            <div className="text-primary flex justify-center gap-10 pt-0">
                {/* <div className="absolute top-10 left-48 text-primary text-2xl font-semibold">
                    Membership Prices Overview
                </div> */}

                <table className="table-auto ml-80 md:ml-10 overflow-x-scroll max-w-[100%]">
                    <thead>
                        <tr>
                            <th className="text-sm md:text-base lg:text-[1vw] px-3 lg:px-[0.8vw] py-3 lg:py-[0.2vw]">Plan</th>
                            <th className="text-sm md:text-base lg:text-[1vw] px-3 lg:px-[0.8vw] py-3 lg:py-[0.2vw]">Single (MRP)</th>
                            <th className="text-sm md:text-base lg:text-[1vw] px-3 lg:px-[0.8vw] py-3 lg:py-[0.2vw]">Single (Exclusive)</th>
                            <th className="text-sm md:text-base lg:text-[1vw] px-3 lg:px-[0.8vw] py-3 lg:py-[0.2vw]">Couple (MRP)</th>
                            <th className="text-sm md:text-base lg:text-[1vw] px-3 lg:px-[0.8vw] py-3 lg:py-[0.2vw]">Couple (Exclusive)</th>
                            <th className="text-sm md:text-base lg:text-[1vw] px-3 lg:px-[0.8vw] py-3 lg:py-[0.2vw]">Family (MRP)</th>
                            <th className="text-sm md:text-base lg:text-[1vw] px-3 lg:px-[0.8vw] py-3 lg:py-[0.2vw]">Family (Exclusive)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[
                        { label: "Single Day", mrpField: "dailyPrice"     , exclusiveField: "exclusiveDailyPrice" },
                        { label: "1 Month"   , mrpField: "monthlyPrice"   , exclusiveField: "exclusiveMonthlyPrice" },
                        { label: "3 Months"  , mrpField: "quarterlyPrice" , exclusiveField: "exclusiveQuarterlyPrice" },
                        { label: "6 Months"  , mrpField: "halfYearlyPrice", exclusiveField: "exclusiveHalfYearlyPrice" },
                        { label: "12 Months" , mrpField: "yearlyPrice"    , exclusiveField: "exclusiveYearlyPrice" },
                        ].map(({ label, mrpField, exclusiveField }) => (
                        <tr key={label}>
                            <td className="text-sm min-w-[100px] md:min-w-[120px] md:text-base lg:text-[0.8vw] px-2 md:px-4 lg:px-[1vw] py-2 lg:py-[0.5vw] text-center font-semibold">{label}</td>
                            
                            {/* Single Prices */}
                            <td className="px-4 py-1 md:py-1 lg:py-[0.2vw] text-sm md:text-base lg:text-[0.85vw] text-center">{formData.prices.single[mrpField] || "-"}</td>
                            <td className="px-4 py-1 md:py-1 lg:py-[0.2vw] text-sm md:text-base lg:text-[0.85vw] text-center">{formData.prices.single[exclusiveField] || "-"}</td>

                            {/* Couple Prices */}
                            <td className="px-4 py-1 md:py-1 lg:py-[0.2vw] text-sm md:text-base lg:text-[0.85vw] text-center">{formData.prices.couple[mrpField] || "-"}</td>
                            <td className="px-4 py-1 md:py-1 lg:py-[0.2vw] text-sm md:text-base lg:text-[0.85vw] text-center">{formData.prices.couple[exclusiveField] || "-"}</td>

                            {/* Family Prices */}
                            <td className="px-4 py-1 md:py-1 lg:py-[0.2vw] text-sm md:text-base lg:text-[0.85vw] text-center">{formData.prices.family[mrpField] || "-"}</td>
                            <td className="px-4 py-1 md:py-1 lg:py-[0.2vw] text-sm md:text-base lg:text-[0.85vw] text-center">{formData.prices.family[exclusiveField] || "-"}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export const EquipmentSelection = ({ formData, setFormData, submitting, goToPreviousSection, goToNextSection, newClasses, setNewClasses, newFacilities, setNewFacilities, newEquipment, setNewEquipment}) => {

    let keyTextSize = 1;

    /**
     * Handles Change in CheckBoxes
     * @param {Object} e
     */
    const handleCheckboxChange = (e, listName) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [listName]: {
                ...prevData[listName],
                [name]: checked
            }
        }));
    };

    /**
     * Adds a new Equipment not listed in the list
     * @param {Object} e
     */
    const handleAddEquipment = (e) => {
        if ((e.key === 'Enter' || e.key === 'Tab') && newEquipment.trim() !== "") {
            handleCheckboxChange({
                target: {
                    name: newEquipment,
                    checked: true,
                }
            }, 'equipmentList');
            setNewEquipment("");
        }
    };

    /**
     * Adds a new Facility not listed in the list
     * @param {Object} e
     */
    const handleAddFacility = (e) => {
        if ((e.key === 'Enter' || e.key === 'Tab') && newFacilities.trim() !== "") {
            handleCheckboxChange({
                target: {
                    name: newFacilities,
                    checked: true,
                }
            }, 'facilities');
            setNewFacilities("");
        }
    };

    /**
     * Adds a new Class not listed in the list
     * @param {Object} e
     */
    const handleAddClass = (e) => {
        if ((e.key === 'Enter' || e.key === 'Tab') && newClasses.trim() !== "") {
            handleCheckboxChange({
                target: {
                    name: newClasses,
                    checked: true,
                }
            }, 'classes');
            setNewClasses("");
        }
    };

    return (
        <>
            <div>
                {/* Equipment List */}
                <div className="mb-8">
                    <h2 className="flex text-left text-xl lg:text-[1.25vw] font-semibold mb-4">Equipments</h2>
                    <div className="flex flex-wrap">
                        <div className="w-1/2">
                            {Object.entries(formData.equipmentList).slice(0, Math.ceil(Object.entries(formData.equipmentList).length / 2)).map(([key, value]) => (
                                <div key={key} className={`mb-2 lg:mb-3 flex items-center`}>
                                    <input
                                        type="checkbox"
                                        id={key}
                                        name={key}
                                        checked={value}
                                        onChange={(e) => handleCheckboxChange(e, 'equipmentList')}
                                        className="mr-2"
                                    />
                                    <label htmlFor={key} className={`text-sm lg:text-[${keyTextSize}vw] font-medium`}>{formatEquipmentName(key)}</label>
                                </div>
                            ))}
                        </div>
                        <div className="w-1/2">
                            {Object.entries(formData.equipmentList).slice(Math.ceil(Object.entries(formData.equipmentList).length / 2)).map(([key, value]) => (
                                <div key={key} className={`mb-2 lg:mb-3 flex items-center`}>
                                    <input
                                        type="checkbox"
                                        id={key}
                                        name={key}
                                        checked={value}
                                        onChange={(e) => handleCheckboxChange(e, 'equipmentList')}
                                        className="mr-2"
                                    />
                                    <label htmlFor={key} className={`text-sm lg:text-[${keyTextSize}vw] font-medium`}>{formatEquipmentName(key)}</label>
                                </div>
                            ))}
                        </div>
                        <div className="w-1/4 mt-4">
                            <input
                                type="text"
                                value={newEquipment}
                                onChange={(e) => setNewEquipment(e.target.value)}
                                onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                                onKeyDown={handleAddEquipment}
                                placeholder="Add More"
                                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                            />
                        </div>
                    </div>
                </div>


                {/* Facilities List */}
                <div className="mb-8">
                    <h2 className="flex text-left text-xl lg:text-[1.25vw] font-semibold mb-4">Facilities</h2>
                    <div className="flex flex-wrap">
                        <div className="w-1/2">
                            {Object.entries(formData.facilities).slice(0, Math.ceil(Object.entries(formData.facilities).length / 2)).map(([key, value]) => (
                                <div key={key} className={`mb-2 lg:mb-3 flex items-center`}>
                                    <input
                                        type="checkbox"
                                        id={key}
                                        name={key}
                                        checked={value}
                                        onChange={(e) => handleCheckboxChange(e, 'facilities')}
                                        className="mr-2"
                                    />
                                    <label htmlFor={key} className={`text-sm lg:text-[${keyTextSize}vw] font-medium`}>{formatEquipmentName(key)}</label>
                                </div>
                            ))}
                        </div>
                        <div className="w-1/2">
                            {Object.entries(formData.facilities).slice(Math.ceil(Object.entries(formData.facilities).length / 2)).map(([key, value]) => (
                                <div key={key} className={`mb-2 lg:mb-3 flex items-center`}>
                                    <input
                                        type="checkbox"
                                        id={key}
                                        name={key}
                                        checked={value}
                                        onChange={(e) => handleCheckboxChange(e, 'facilities')}
                                        className="mr-2"
                                    />
                                    <label htmlFor={key} className={`text-sm lg:text-[${keyTextSize}vw] font-medium`}>{formatEquipmentName(key)}</label>
                                </div>
                            ))}
                        </div>
                        <div className="w-1/4 mt-4">
                            <input
                                type="text"
                                value={newFacilities}
                                onChange={(e) => setNewFacilities(e.target.value)}
                                onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                                onKeyDown={handleAddFacility}
                                placeholder="Add More"
                                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                            />
                        </div>
                    </div>
                </div>


                {/* Classes List */}
                <div className="mb-8">
                    <h2 className="flex text-left text-xl lg:text-[1.25vw] font-semibold mb-4">Classes</h2>
                    <div className="flex flex-wrap">
                        <div className="w-1/2">
                            {Object.entries(formData.classes).slice(0, Math.ceil(Object.entries(formData.classes).length / 2)).map(([key, value]) => (
                                <div key={key} className={`mb-2 lg:mb-3 flex items-center`}>
                                    <input
                                        type="checkbox"
                                        id={key}
                                        name={key}
                                        checked={value}
                                        onChange={(e) => handleCheckboxChange(e, 'classes')}
                                        className="mr-2"
                                    />
                                    <label htmlFor={key} className={`text-sm lg:text-[${keyTextSize}vw] font-medium`}>{formatEquipmentName(key)}</label>
                                </div>
                            ))}
                        </div>
                        <div className="w-1/2">
                            {Object.entries(formData.classes).slice(Math.ceil(Object.entries(formData.classes).length / 2)).map(([key, value]) => (
                                <div key={key} className={`mb-2 lg:mb-3 flex items-center`}>
                                    <input
                                        type="checkbox"
                                        id={key}
                                        name={key}
                                        checked={value}
                                        onChange={(e) => handleCheckboxChange(e, 'classes')}
                                        className="mr-2"
                                    />
                                    <label htmlFor={key} className={`text-sm lg:text-[${keyTextSize}vw] font-medium`}>{formatEquipmentName(key)}</label>
                                </div>
                            ))}
                        </div>
                        <div className="w-1/4 mt-4">
                            <input
                                type="text"
                                value={newClasses}
                                onChange={(e) => setNewClasses(e.target.value)}
                                onKeyDown={handleAddClass}
                                onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                                placeholder="Add More"
                                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Navigation buttons */}
            <div className="flex justify-center gap-10 mt-10">
                <button type='button' onClick={goToPreviousSection} className="px-4 py-2 bg-secondary rounded-full w-28 text-tertiary hover:bg-primary hover:scale-110 transition-all duration-300">
                    &lt; Back
                </button>

                {/* Submit Button */}
                <button type="button" onClick={goToNextSection} className={`bg-secondary text-tertiary py-2 px-4 w-28 rounded-full ${submitting ? '' : 'hover:bg-primary hover:scale-110 transition-all duration-300'} hover:bg-primary hover:scale-110 transition-all duration-300`} disabled={submitting}>
                    Next &gt;
                </button>
            </div>
        </>
    )
}

export const AddPhotos = ({ formData, submitting, goToPreviousSection, handleMultiImagesUpload, handleImageChange, handleUploadToFirestore, uploading, selectedImages, handleSubmit, isGymForm}) => {
    let fieldTextSize = 1;
    let fieldValueTextSize = 1;
    let spacing  = 1;

    console.log("Selecetd Images ; ", selectedImages)

    return (
        <div className='flex flex-col'>
            {(isGymForm ? formData?.gymLogo !== '' : formData?.studioLogo !== '') && 
                <div className="flex flex-col">
                    <div className="font-semibold text-xl">Current Logo: </div>
                    <img src={formData?.gymLogo || formData?.studioLogo} alt='' className="h-[90vw] w-[90vw] lg:h-[40vh] lg:w-[40vh]"/>
                </div>
            }

            <div className={`mb-4 lg:mb-[${spacing}vw] flex lg:flex-row flex-col lg:items-center`}>
                <label className={`flex text-left lg:w-1/4 lg:text-[${fieldTextSize}vw] font-semibold`}>
                    Upload {isGymForm ? 'Gym' : 'Studio'} Logo
                </label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className={`w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[${fieldValueTextSize}vw]`}
                />
            </div>

            <div className={`mb-4 lg:mb-[${spacing}vw] flex flex-col mt-4`}>
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <label className={`flex text-left lg:w-1/5 lg:text-[${fieldTextSize}vw] font-semibold mb-2`}>
                        Upload {isGymForm ? 'Gym' : 'Studio'} Images
                    </label>
                    <input type="file" multiple onChange={handleMultiImagesUpload} accept="image/*" />
                    <button 
                        type='button' 
                        onClick={handleUploadToFirestore} 
                        disabled={uploading} 
                        className="mt-4 lg:mt-0 px-6 py-2 rounded-full bg-secondary text-white"
                    >
                        {uploading ? "Uploading..." : "Upload Images"}
                    </button>
                </div>

                {/* Image Preview */}
                <div className="flex gap-4 mt-8 max-w-[90vw] lg:max-w-[80vw] overflow-x-scroll">
                    {selectedImages.map((file, index) => (
                        <img key={index} src={URL.createObjectURL(file)} alt="Preview" className="h-[30vh] w-fit" />
                    ))}
                </div>
            </div>

            {/* Navigation buttons */}
            <div className="flex justify-center gap-10 mt-[10vh]">
                <button type='button' onClick={goToPreviousSection} className="px-4 py-2 bg-secondary rounded-full w-28 text-tertiary hover:bg-primary hover:scale-110 transition-all duration-300">
                    &lt; Back
                </button>

                {/* Submit Button */}
                <button 
                    type     = "button"
                    disabled = {formData?.Portfolio?.length === 0 || submitting}
                    onClick  = {() => formData?.Portfolio?.length < 5 ? alert("Please upload atleast 5 images.") : handleSubmit()}
                    className= {`bg-secondary text-tertiary py-2 px-4 w-28 rounded-full ${submitting ? '' : 'hover:bg-primary hover:scale-110 transition-all duration-300'} hover:bg-primary hover:scale-110 transition-all duration-300`}>
                    Submit
                </button>
            </div>
        </div>
    )
}