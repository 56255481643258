import React, { useState } from "react";
import Modal from "react-modal";
import SelectionScreen from "../components/LandingPageComponents/SelectionScreen";
import UserOnboarding from "../components/LandingPageComponents/UserOnboarding";

/**
 * LandingPage Component
 *
 * @param {Function} setIsSigningIn    -   function to display the signIn Modal
 */

const LandingPage = ({setIsSigningIn}) => {
    const [isUserSelected, setIsUserSelected] = useState(false);
    const [isFitnessCenterModalOpen, setIsFitnessCenterModalOpen] = useState(false);

    /**
     * Handles selection between User and Fitness Center.
     */
    const handleSelection = (type) => {
        if (type === "user") {
            window.location.href = "https://trainrexofficial.com/"
        } else {
            setIsUserSelected(true);
            setIsFitnessCenterModalOpen(true);
        }
    };

    return (
        <div className="landing-container">
            {!isUserSelected ? (
                <SelectionScreen handleSelection={handleSelection} />
            ) : (
                <UserOnboarding setIsSigningIn={setIsSigningIn} />
            )}

            {/* Fitness Center Modal */}
            <Modal
                isOpen={isFitnessCenterModalOpen}
                onRequestClose={() => setIsFitnessCenterModalOpen(false)}
                contentLabel="Fitness Center Registration"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <h2 className="modal-title">Fitness Center Registration</h2>
                <p className="modal-text">
                    We are thrilled to have your fitness center onboard! Please proceed with the registration process.
                </p>
                <button className="primary-button" onClick={() => setIsFitnessCenterModalOpen(false)}>
                    Close
                </button>
            </Modal>
        </div>
    );
};

export default LandingPage;
