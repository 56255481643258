import { collection, doc, getDoc, getDocs, setDoc, updateDoc, query, where, limit, Timestamp } from "@firebase/firestore";
import { db, dbGym, storage } from "../config/firebase"
import { IsCityValid, IfGymDocExists, addCollectionsToGyms, IfStudioDocExists, addCollectionsToStudios } from "./GymFormHelpersExtended";
import { cityList, gymList, studioList } from "../components/Databases";
import { formatWordToLowerCase, getSellingPrice } from './basicHelper';
import { getCoordinatesFromAddress } from './CoordinatesHelpers';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { addGymToAlgolia } from "./AlgoliaSearch/AddGymToAlgolia";


/**
 * Stores the City of the Gym
 * (Needs to check if the City already exists or not)
 * @param   :   City Name
 * @return  :   bool - true if success; false otherwise
 */
export const storeCity = async (cityName) => {
    try {
        // Check if the city is a valid city or not
        // TO BE DONE IN THE FUTURE
        // using Geocoding API
        if(!IsCityValid(cityName)){
            return false;
        }


        // Database handling
        const cityRef = collection(db, cityList);
        const cityDocRef = doc(cityRef, formatWordToLowerCase(cityName));
        const cityDoc = await getDoc(cityDocRef);


        // If the City is already listed, return
        if (cityDoc.exists()) {
            return true;
        }
        // Else add the City to the database
        await setDoc(cityDocRef, {
            'city'          :   cityName,   // Name of the city
            'NoOfGyms'      :   0,          // Keeps track of the total gyms that ever partnered with us
            'NoOfActiveGyms':   0           // Keeps track of the active gyms in the locality
        });


        return true;




    } catch (error) {
        console.error("Error storing city : ", error);
        return false;
    }
}


/**
 * Stores the Gym Details
 * (Needs to check if the Gym already exists or not)
 * If yes, change the status to true.
 * @param   :   •   City
 *              •   Gym Name
 *              •   Locality
 *              •   Owner's Name
 *              •   Contact Number
 * @return  :   bool - true if success; false otherwise
 */
export const storeGym = async (info, user) => {
    try {
        // Fetch the coordinates using the address
        // const coordinates = await getCoordinates(info.address, info.locality, info.city, info.country);

        // If no coordinates are found, stop the process
        // if (!coordinates) {
        //     throw new Error('Unable to fetch coordinates for the address');
        // }

        const toUpdate = {
            ...info,
            gymID                   :   user.uid,
            name                    :   info.gymName,
            gymDisplayName          :   info.gymDisplayName,
            codeName                :   info.gymName.toLowerCase().replace(/\s+/g, ''),  // This is done to find the same gym if exists
            gymName                 :   info.gymName.toLowerCase().replace(/\s+/g, ''),  // Same as above for consistency
            status                  :   true,
            gstin                   :   info.gstin==='' ? '-' : info.gstin,              // if gstin field is empty then "-" will be stored in the database else the value of the field
            isWomensOnly            :   info.isWomensOnly ? true : false,
            rating                  :   0,                                               // Default rating when gym is created
            NoOfReview              :   0,
            SumOfReviews            :   0,
            Portfolio               :   info.Portfolio || '<url>',                       // Default URL for portfolio
            isExclusive             :   info?.exclusiveModel ? info?.exclusiveModel : false,                             // True if exclsuive model is selected
            commissionPercentage    :   info?.exclusiveModel ? 0 : 20,
        };


        // Add Gym to Gym Database (trainrex website)
        await addUserToGymDoc(user, info);


        // Database management
        const gymRef    = collection(db, cityList, info.city, gymList);
        const gymDocRef = doc(gymRef, info.gymName);


        // gymName Reference to find the gym with same "codeName"
        const gymQuery = query(gymRef, where('gymName', '==', toUpdate.gymName), limit(1));
        const gymDocs = await getDocs(gymQuery);


        // Check if the gym exists already
        // If yes, change the status to active
        if (!gymDocs.empty) {
            // Get the first document
            const gymDoc = gymDocs.docs[0];
            // console.log('Gym document data:', gymDoc.data());
            return await IfGymDocExists(info.city, gymDoc, gymDocRef, toUpdate);
        }


        // Add Details to the Website database, if gym doesn't exist
        await setDoc (gymDocRef , toUpdate);


        /*
         * Add further collections
         * Reviews  : Keeps Track of the reviews of the gym. Doc have field like    :   • Name of Reviewer
         *                                                                              • Review Date
         *                                                                              • Rating
         *                                                                              • Feedback
         *                                                                              • Suggestions for improvement
         *
         * Users    : Keeps Record of all the users. Users have fields like         :   • User Name
         *                                                                              • Member Since
         *                                                                              • User ID
         *                                                                              • Gym Membership ID
         *                                                                              • Address
         *                                                                              • Identification Number ? (like aadhar)
         *
         * Calendar : Keeps A tab of all the dates. These dates have fields like    :   • Timestamp
         *                                                                              • totalUsersAttendance  -   to keep a track of total users who came
         *                                                                              • Year
         *                                                                              • Month
         *                                                                              • Date
         *                                                  - Further Collections   :   • Attendance (individually see which users came)
         *                                                                              • Workout (this keeps track of what machines have users used on a specific day)
         *                                                                              • Classes (the classes the fitness centre is offereing on that specific date)
         *
         * Finances :   Keeps a tab of all the centre's finances, fields are like   :   • {Years} containing
         *                                                                              • TotalRevenue
         *                                                                              • TotalExpense
         *                                                  - Further Collections   :   • Revenue (Both has month like segregation and contain receits)
         *                                                                              • Expense
         *          
         * Staff    : Keeps Record of all the staff members. Have fields like       :   • Staff Name
         *                                                                              • Staff ID
         *                                                                              • Staff Email
         *                                                                              • Salary
         *                                                                              • Position
         *                                                                              • Identification Number ? (like aadhar)
         * Classes :  Keeps record of all the classes of the Gyms. Fields Like      :   • class Schedule
         *                                                                              • available slots
         *                                                                              • price for members
         *                                                                              • price for non members
         *                                                                              • trainer
         */
        await addCollectionsToGyms(gymDocRef);


        // Update the number of gyms of the city
        const cityRef = collection(db, cityList);
        const cityDocRef = doc(cityRef, info.city);
        const cityDoc = await getDoc(cityDocRef);
        const cityData = cityDoc.data();


        await updateDoc(cityDocRef, {
            'NoOfActiveGyms'    : cityData.NoOfActiveGyms + 1,
            'NoOfGyms'          : cityData.NoOfGyms + 1
        });

        // ✅ Add Gym to Algolia Search
        try {
            if(info.city !== 'testcity')
                await addGymToAlgolia([toUpdate], info.city);
        } catch (error) {
            console.log('Not able to add to algolia');
        }

        return true;


    } catch (error) {
        console.error("Error storing Gym : ", error);
        return false;
    }
}


const addUserToGymDoc = async(user, info) => {
    try {
        // console.log('Adding Gym to Firestore');
        const userRef = doc(dbGym, cityList, info.city, gymList, user.uid);
        await setDoc(userRef, info);


        // Adding to the the website firebase
        const gymRef = doc(dbGym, gymList, user.uid);
        await setDoc(gymRef, info);
        return true;
    } catch (error) {
        console.error("Error adding user to Firestore", error);
        return false;
    }
};

export const storeStudio = async (info, user) => {
    try {
        const toUpdate = {
            joined                  :   Timestamp.now(),
            studioID                :   user.uid,
            name                    :   info.studioName,
            codeName                :   info.studioName.toLowerCase().replace(/\s+/g, ''),  // This is done to find the same studio if exists
            studioName              :   info.studioName.toLowerCase().replace(/\s+/g, ''),  // Same as above for consistency
            status                  :   true,
            gstin                   :   info.gstin==='' ? '-' : info.gstin,              // if gstin field is empty then "-" will be stored in the database else the value of the field
            isWomensOnly            :   info.isWomensOnly ? true : false,
            rating                  :   0,                                               // Default rating when studio is created
            NoOfReview              :   0,
            SumOfReviews            :   0,
            Portfolio               :   info.Portfolio || '<url>',                       // Default URL for portfolio
            isExclusive             :   true,                             // True if exclsuive model is selected
            commissionPercentage    :   20,
            ...info,
        };

        // Add studio to studio Database (trainrex website)
        await addUserToStudioDoc(user, info);


        // Database management
        const studioRef    = collection(db, cityList, info.city, studioList);
        const studioDocRef = doc(studioRef, info.studioName);


        // studioName Reference to find the studio with same "codeName"
        const studioQuery = query(studioRef, where('studioName', '==', toUpdate.studioName), limit(1));
        const studioDocs = await getDocs(studioQuery);


        // Check if the studio exists already
        // If yes, change the status to active
        if (!studioDocs.empty) {
            // Get the first document
            const studioDoc = studioDocs.docs[0];
            // console.log('studio document data:', studioDoc.data());
            return await IfStudioDocExists(info.city, studioDoc, studioDocRef, toUpdate);
        }


        // Add Details to the Website database, if studio doesn't exist
        await setDoc (studioDocRef , toUpdate);


        /*
         * Add further collections
         * Reviews  : Keeps Track of the reviews of the studio. Doc have field like    :   • Name of Reviewer
         *                                                                              • Review Date
         *                                                                              • Rating
         *                                                                              • Feedback
         *                                                                              • Suggestions for improvement
         *
         * Users    : Keeps Record of all the users. Users have fields like         :   • User Name
         *                                                                              • Member Since
         *                                                                              • User ID
         *                                                                              • studio Membership ID
         *                                                                              • Address
         *                                                                              • Identification Number ? (like aadhar)
         *
         * Calendar : Keeps A tab of all the dates. These dates have fields like    :   • Timestamp
         *                                                                              • totalUsersAttendance  -   to keep a track of total users who came
         *                                                                              • Year
         *                                                                              • Month
         *                                                                              • Date
         *                                                  - Further Collections   :   • Attendance (individually see which users came)
         *                                                                              • Workout (this keeps track of what machines have users used on a specific day)
         *                                                                              • Classes (the classes the fitness centre is offereing on that specific date)
         *
         * Finances :   Keeps a tab of all the centre's finances, fields are like   :   • {Years} containing
         *                                                                              • TotalRevenue
         *                                                                              • TotalExpense
         *                                                  - Further Collections   :   • Revenue (Both has month like segregation and contain receits)
         *                                                                              • Expense
         *          
         * Staff    : Keeps Record of all the staff members. Have fields like       :   • Staff Name
         *                                                                              • Staff ID
         *                                                                              • Staff Email
         *                                                                              • Salary
         *                                                                              • Position
         *                                                                              • Identification Number ? (like aadhar)
         * Classes :  Keeps record of all the classes of the studios. Fields Like      :   • class Schedule
         *                                                                              • available slots
         *                                                                              • price for members
         *                                                                              • price for non members
         *                                                                              • trainer
         */
        await addCollectionsToStudios(studioDocRef);


        // Update the number of studios of the city
        const cityRef = collection(db, cityList);
        const cityDocRef = doc(cityRef, info.city);
        const cityDoc = await getDoc(cityDocRef);
        const cityData = cityDoc.data();

        await updateDoc(cityDocRef, {
            NoOfActiveStudios: (typeof cityData.NoOfActiveStudios === 'number' ? cityData.NoOfActiveStudios : 0) + 1,
            NoOfStudios: (typeof cityData.NoOfStudios === 'number' ? cityData.NoOfStudios : 0) + 1
        });

        // ✅ Add Gym to Algolia Search
        try {
            if(info.city !== 'testcity')
                await addGymToAlgolia([toUpdate], info.city);
        } catch (error) {
            console.log('Not able to add to algolia');
        }

        return true;


    } catch (error) {
        console.error("Error storing studio : ", error);
        return false;
    }
}


export const addUserToStudioDoc = async(user, info) => {
    try {
        // console.log('Adding studio to Firestore');
        const userRef = doc(dbGym, cityList, info.city, studioList, user.uid);
        await setDoc(userRef, info);
        
        
        // Adding to the the website firebase
        const studioRef = doc(dbGym, studioList, user.uid);
        await setDoc(studioRef, info);
        return true;
    } catch (error) {
        console.error("Error adding user to Firestore", error);
        return false;
    }
};


export const getDefaultFormDataState = (user) => {
    return {
        city                : '',
        gym                 : '',
        locality            : '',
        owner               : '',
        phoneNumber         : user.phoneNumber,
        gymContactNo        : '',
        address             : '',
        openingTime         : '',
        closingTime         : '',
        timeSlots           : {
            Monday      : [{ openingTime: '', closingTime: '' }],
            Tuesday     : [{ openingTime: '', closingTime: '' }],
            Wednesday   : [{ openingTime: '', closingTime: '' }],
            Thursday    : [{ openingTime: '', closingTime: '' }],
            Friday      : [{ openingTime: '', closingTime: '' }],
            Saturday    : [{ openingTime: '', closingTime: '' }],
            Sunday      : [{ openingTime: '', closingTime: '' }],
        },
        gymLogo             : '',
        Portfolio           : [],
        email               : user.email,
        gstin               : '',
        country             : '',
        pinCode             : '',
        isWomensOnly        : false,
        offersFreeTrial     : false,
        OffersEMI           : false,

        //Bank Details of gym owner
        accountName         : '',
        accountCategory     : '',
        accountNumber       : '',
        accountType         : '',
        ifscCode            : '',
        
        // Pricing Structure (Single, Couple, Family)
        paymentModel        : '',             // if Exclusive is selected then it is true else it is false
        exclusiveModel      : false,          // if Exclusive is selected then it is true else it is false
        prices              : {
            single              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            },
            couple              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            }, 
            family              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            },
        },

        // Equipment List
        equipmentList       : {
            AbductionAndAdductionMachine    : false,    
            AdductorMachine                 : false,
            ArmWrestlingPlatform            : false,
            BackrollMachine                 : false,
            BattleRope                      : false,
            CableCrossover                  : false,
            CableRowMachine                 : false,
            CalfMachine                     : false,
            CrossTrainer                    : false,
            ChestPress                      : false,
            DeclineBench                    : false,
            DeclinePress                    : false,
            Dumbbells                       : false,
            FlatBench                       : false,
            HackSquatMachine                : false,
            HyperExtension                  : false,
            InclineBench                    : false,
            LatPullDown                     : false,
            LegCurlMachine                  : false,
            LegExtension                    : false,
            LegPress                        : false,
            PeckDeckFly                     : false,
            PreacherCurlMachine             : false,
            PullUpBar                       : false,
            RowingMachine                   : false,
            SkiingMachine                   : false,
            SmithMachine                    : false,
            ShoulderPress                   : false,
            SquatBar                        : false,
            StairMaster                     : false,
            StationaryBicycle               : false,
            Treadmill                       : false,
            CurveTreadmill                  : false,
            Tyre                            : false
        },

        // Facilities
        facilities          : {
            Spa                 : false,
            Sauna               : false,
            CardioArea          : false,
            ChangingRoom        : false,
            StrengthArea        : false,
            LoungeAndCafe       : false,
            Locker              : false,
            Shower              : false,
            BoxingRing          : false,
            ValetParking        : false,
            WallClimbing        : false,
            PersonalTrainer     : false,
            Washrooms           : false,
            AirConditioner      : false,
            FullyAirConditioned : false,
        },
        
        // Classes
        classes             : {
            MMA                 : false,
            Zumba               : false,
            HIIT                : false,
            FunctionalTraining  : false,
            CoreClasses         : false,
            KickBoxing          : false,
            Aerobics            : false,
            Pilates             : false,
        },
    };
}

export const getDefaultStudioFormDataState = (user) => ({
  city                  : "",
  studio                : "",
  locality              : "",
  owner                 : "",
  phoneNumber           : user?.phoneNumber || "",
  studioContactNo       : "",
  address               : "",
  classesSchedule         : {
    Monday                  : {},
    Tuesday                 : {},
    Wednesday               : {},
    Thursday                : {},
    Friday                  : {},
    Saturday                : {},
    Sunday                  : {},
  },
  duration              : {},
  availableSlots        : {},
  trainerNames          : {},
  specialInstructions   : {},
  studioLogo            : "",
  Portfolio             : [],
  email                 : user?.email || "",
  gstin                 : "",
  country               : "",
  pinCode               : "",
  isWomensOnly          : false,

  // Bank Details
  accountName           : "",
  accountCategory       : "",
  accountNumber         : "",
  accountType           : "",
  ifscCode              : "",

  // Pricing Structure
  paymentModel          : "Exclusive",
  exclusiveModel        : true,
  prices                : {
    single: {},
    // couple: {},
    // family: {},
  },

  // Equipment List
  equipmentList: {
    YogaMats                : false,
    ResistanceBands         : false,
    StabilityBalls          : false,
    FoamRollers             : false,
    DumbbellsLightWeight    : false, // Lightweights for functional movements
    StepPlatforms           : false,
    SoundSystem             : false,
    ExerciseBlocks          : false,
    Straps                  : false,
    PilatesRing             : false,
    Bolsters                : false,
    MeditationCushions      : false,
  },
  
  // Facilities relevant for yoga, Zumba, and similar studios
  facilities: {
    AirConditioning         : false,
    Mirrors                 : false,
    ChangingRooms           : false,
    WaterDispenser          : false,
    Washrooms               : false,
    AmbientLighting         : false,
    WoodenFlooring          : false,
    WaitingLounge           : false,
    MusicSystem             : false,
    Ventilation             : false,
    PersonalStorage         : false,
    AromaTherapySupport     : false, // Optional but trending for wellness studios
    Parking                 : false,
    Lockers                 : false,
  }
});

/**
 * Checks if the email and phone number of the gym is already registered in the authentication, if its present in the gyms collection 
 * @param {*} phone - phone number of the user 10 digits
 * @returns 
 */
export const checkIfUserIsAlreadyRegistered = async (phone) => {
    try {
        const gymsRef = collection(dbGym, gymList); // Reference to the "gyms" collection
        const q = query(gymsRef, where("phoneNumber", "==", phone));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // Return the first matching document
            return querySnapshot.docs[0].data();
        } else {
            return null; // No user found
        }
    } catch (error) {
        console.error("Error checking user registration:", error);
        return null;
    }
};

export const getGymData = async (email) => {
    const gymCollectionRef = collection(dbGym, gymList); // Reference to gym collection
        const q = query(gymCollectionRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        let userFirestoreData = null;
        let userDocID = null;

        querySnapshot.forEach((doc) => {
            userFirestoreData = doc.data();
            userDocID = doc.id; // Get the document ID
        });

        return userFirestoreData;
}

/**
 * Uploads single image to firestore for both gyms and studios and returns the URL to access the photo
 * @param {image}  file         - image to be uploaded
 * @param {string} gymName      - gym or studio name
 * @param {string} gymCity      - gym or studio city
 * @param {string} studioOrGym  - to check in which folder to upload photos "gyms" or "studios"
 * @returns {string} - URL of the stored image
 */
export const uploadImage = async (file, gymName, gymCity, studioOrGym) => {
    try {
       
        const storageRef = ref(storage, `${studioOrGym}/${gymName}-${gymCity}/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        // console.log("Image URL  : ", downloadURL)
        return downloadURL; // Return for further use
    } catch (error) {
        console.error("Upload failed:", error);
        return null;
    }
};