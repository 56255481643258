import React, { useEffect, useState } from "react";
import GymSignIn from "./DashBoardScenes/GymSignIn";
import DashboardScenesController from "./DashBoardScenes/DashboardScenesController";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase";
import {getGymData} from '../../helperFunctions/GymFormHelper'
import { useNavigate } from 'react-router-dom';
import LandingPage from "../LandingPage";


const GymsDashboard = () => {
    const [isLoggedIn	, setIsLoggedIn		] 	= 	useState(false); // State to track login status
	const [isSigningIn 	, setIsSigningIn	]	=	useState(false);
    const [user			, setUser			] 	= 	useState(null); // State to store user information
    const navigate = useNavigate();

    // Listener to handle authentication state changesp
    useEffect(() => {
      	const authStateChangeListener = onAuthStateChanged(auth, (user) => {
			if (user) {
			setUser(user);
			setIsLoggedIn(true);
			} else {
			setUser(null);
			setIsLoggedIn(false);
			}
      	});
      	// Cleanup the listener on component unmount
      	return () => authStateChangeListener();
    }, []);

    /**
     * On Successful Sign In, this recovers user from GymSignIn
     * @param {user} userData
     */
    const handleSignIn = async (userData) => {
      try {
  
          setUser(userData);
          const data = {
              uid: userData.uid,
              email: userData.email,
              phoneNumber: userData.phoneNumber,
          };
          const userFirestoreData = await getGymData(userData.email);
  
          if (!userFirestoreData || userFirestoreData?.accountNumber === '') {
              
              alert("Please complete your data, redirecting to GymForm...");
              navigate('/GymForm', { state: { user: data } });
              return; // Prevent further execution
          }
          setIsLoggedIn(true);
      } catch (error) {
          console.error("Error in handleSignIn:", error);
          alert(`An error occurred: ${error.message}, please contact the technical team at 7428-311-178`);
      }
  };
  

    return (
      <div className="flex flex-col min-h-screen bg-tertiary justify-center">
        {!isLoggedIn ? (
			!isSigningIn ? (
				<LandingPage setIsSigningIn={setIsSigningIn}/>
			) : (
				<GymSignIn onSignIn={handleSignIn} />
			)
        ) : (
          <DashboardScenesController user={user} />
        )} 
      </div>
    );
};

export default GymsDashboard;
