function GymServicesAgreement() {
    return (
        <div className="bg-tertiary text-left px-16 py-8 justify-start">
            <h1 className="text-3xl font-bold mb-4">Gym Services Agreement</h1>
            
            <p className="mb-6 font-semibold">Last Updated: 13th December 2024</p>
            
            <h3 className="text-2xl font-semibold mb-2">Welcome to Train Rex!</h3>
            <p className="mb-6">
            These Terms of Use ("Terms") constitute a legally binding agreement between you and Train Rex ("Train Rex" or "we") and govern your access to and use of the Train Rex Marketplace (the "Marketplace"), including any content, fitness-related experiences, activities, services, or products offered through Train Rex (collectively, "Services"). Please read these Terms carefully before accessing or using the Marketplace.
The most current version of these Terms can be accessed at any time. Train Rex reserves the right to amend these Terms at its discretion. Any changes to these Terms will be communicated through a posting on the Marketplace or through other forms of notification. By continuing to use the Marketplace after any amendments, you acknowledge your acceptance of the revised Terms.

            </p>
            
            <h3 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h3>
            <p className="mb-6">
                <span className="font-semibold">1.1</span> By accessing or getting listed on the Marketplace and Services, or by clicking any button indicating consent, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you are prohibited from being listed on the Marketplace or any Services.
                <br/><br/>
                <span className="font-semibold">1.2</span> Train Rex may, at its sole discretion, update or modify these Terms from time to time. Unless otherwise specified, such modifications will become effective immediately upon their posting. Continued use of the Marketplace or Services following the posting of modifications constitutes your acceptance of the revised Terms.
                <br/><br/>
                <span className="font-semibold">1.3</span> Certain Services, plans, or features offered through the Marketplace may be subject to additional terms, conditions, or guidelines. In the event of a conflict between these Terms and any additional terms, these Terms shall govern.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2 text-left">2. Membership Sales and Pricing Structure</h3>
            <p className="mb-6 text-left">
                <span className="font-semibold">2.1</span> Train Rex offers two primary models for the sale of gym memberships:
            </p>
            <p className="ml-8 mb-4">
                <span className="font-semibold">a) Commission-Based Model:</span> Train Rex shall receive a commission equal to 20% (or specified otherwise) of the total cost of each gym membership sold through the Marketplace. Fitness centres and gyms are responsible for the payment of this commission.
                <br/><br/>
                <span className="font-semibold">b) Exclusive Pricing Model:</span> Fitness centres and gyms agree to offer exclusive pricing to Train Rex, with the understanding that such pricing shall not be offered at a lower rate through any other channel. Train Rex reserves the right to sell the membership at a higher or lower price to customers but shall only pay the exclusive price agreed upon with the gym.
            </p>
            <p className="mb-6 text-left">
                <span className="font-semibold">2.2</span> By participating in the Marketplace, fitness centres and gyms agree to abide by these pricing structures.
            </p>

            
            <h3 className="text-2xl font-semibold mb-2">3. Promotional Materials</h3>
            <p className="mb-6">
                <span className="font-semibold">3.1</span> Fitness centres and gyms that are listed on the Marketplace agree to allow Train Rex to display promotional materials within their facilities. These materials may include, but are not limited to, QR codes, standees, posters, and other forms of promotional content. Fitness centres and gyms shall ensure that these materials are displayed in accessible and visible locations within their premises.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">4. Cancellation Policy</h3>
            <p className="mb-6">
                <span className="font-semibold">4.1</span> All membership purchases made through Train Rex are refundable, unless otherwise stated by the Gym. The buyer can terminate their membership up to a certain time duration if bought through the Marketplace. Gyms can also carry out cancellations if they do not deem the user fit for their gym environment.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">5. Fitness Centers' Right to Admission</h3>
            <p className="mb-6">
                <span className="font-semibold">5.1</span> Fitness centres and gyms reserve the right to refuse or revoke admission to any individual at their discretion, including but not limited to cases where users have violated gym policies, engaged in disruptive behaviour, or otherwise acted in a manner inconsistent with the gym’s code of conduct. Train Rex bears no responsibility for the enforcement of such policies but encourages all users to comply with the rules established by the fitness centres.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">6. Code of Conduct for Fitness Centers</h3>
            <p className="mb-6">
                <span className="font-semibold">6.1</span> Fitness centres and gyms listed on Train Rex shall refrain from making any negative or disparaging remarks regarding Train Rex. Any attempt to publicly discredit Train Rex, whether through verbal communication, print, or digital platforms, may result in the removal of the fitness centre or gym from the Marketplace.
                <br/><br/>
                <span className="font-semibold">6.2</span> Fitness centres and gyms are expected to maintain a professional relationship with Train Rex and resolve any disputes or concerns through the appropriate channels.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">7. Gym Account Registration</h3>
            <p className="mb-6">
                <span className="font-semibold">7.1</span>  In order to list fitness centres on the Marketplace, gyms may be required to create an account. By creating an account, gyms agree to provide accurate, complete, and up-to-date information.
                <br/><br/>
                <span className="font-semibold">7.2</span>  Train Rex shall not be liable for any unauthorised access to user accounts due to the gym’s failure to safeguard their credentials.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">8. Payments and Fees</h3>
            <p className="mb-6">
                <span className="font-semibold">8.1</span> Users are obligated to pay all fees associated with the purchase of gym memberships or other Services provided through the Marketplace. All payments made to Train Rex are final, and refunds shall only be issued if expressly stated.
                <br/><br/>
                <span className="font-semibold">8.2</span> Train Rex may charge fitness centres and gyms additional fees for premium services, such as promotional features or enhanced listings. Any such fees shall be clearly communicated during the transaction process.
                <br/><br/>
                <span className="font-semibold">8.3</span> All the payments made through Train Rex will be transferred to the fitness centres and gyms on a weekly basis ie–Monday to Monday. Any delays in the payment shall be exclusively stated by Train Rex at least 2 days prior to the deadline.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">9. Intellectual Property</h3>
            <p className="mb-6">
                <span className="font-semibold">9.1</span> All content available on the Marketplace, including text, graphics, logos, images, and software, is the intellectual property of Train Rex or its licensors. Users are prohibited from reproducing, distributing, or creating derivative works from any content on the Marketplace without prior written consent from Train Rex.
                <span className="font-semibold">9.2</span> Fitness centres and gyms retain ownership of any content they provide to Train Rex for listing purposes. By providing content, they grant Train Rex a non-exclusive, royalty-free licence to use, display, and distribute such content for promotional and operational purposes.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">10. Limitation of Liability</h3>
            <p className="mb-6">
                <span className="font-semibold">10.1</span> The Marketplace and Services provided by Train Rex are made available on an "as is" basis without any warranties of any kind, whether express or implied. Train Rex makes no representations or warranties regarding the availability, accuracy, or reliability of any listings or Services offered through the Marketplace.
                <br/><br/>
                <span className="font-semibold">10.2</span> To the fullest extent permitted by applicable law, Train Rex shall not be liable for any indirect, incidental, or consequential damages arising out of or related to the use of the Marketplace or the purchase of any memberships.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">11. Indemnification</h3>
            <p className="mb-6">
                <span className="font-semibold">11.1</span> You agree to indemnify, defend, and hold harmless Train Rex, its officers, directors, employees, and agents from and against any claims, damages, losses, or expenses, including reasonable attorney’s fees, arising out of your use of the Marketplace, any breach of these Terms, or any violation of applicable laws or third-party rights.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">12. Termination of Access</h3>
            <p className="mb-6">
                <span className="font-semibold">12.1</span> Train Rex reserves the right, at its sole discretion, to terminate or suspend your listing on the Marketplace at any time and without notice for any reason, including violation of these Terms or engagement in any illegal activity. Upon termination, your obligations under these Terms shall continue to apply, as appropriate.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">13. Governing Law and Dispute Resolution</h3>
            <p className="mb-6">
                <span className="font-semibold">13.1</span> This Agreement shall be governed by and construed in accordance with the laws of the Republic of India. The courts at New Delhi shall have exclusive jurisdiction over any matter arising out of this Agreement.
                <br/><br/>
                <span className="font-semibold">13.2 Arbitration :</span> If any dispute or difference of any kind whatsoever shall arise amongst the concerned Parties in connection with, or arising out of, this Agreement (“Dispute”), the concerned Parties shall seek to resolve any such Dispute by mutual consultation and negotiation in good faith. In the event, the concerned Parties are unable to, within 30 (Thirty) days, reach a resolution, such Dispute shall be referred to final and binding arbitration under the (Indian) Arbitration and Conciliation Act, 1996. Such arbitration shall be held in Delhi, India. All proceedings of such arbitration shall be in the English language. A sole arbitrator shall be mutually appointed by the Parties. The award pronounced by the arbitrator shall be final, conclusive, and binding upon the concerned Parties.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">14. Miscellaneous</h3>
            <p className="mb-6">
                <span className="font-semibold">14.1 Entire Agreement : </span> This Agreement constitutes the entire agreement between the Parties and supersedes all prior agreements, understandings, and communications.
                <br/><br/>
                <span className="font-semibold">14.2 Amendments : </span> Any amendments to this Agreement must be in writing and signed by both Parties.
                <br/><br/>
                <span className="font-semibold">14.3 Waiver : </span>  No failure or delay by either Party in exercising any right, power, or privilege under this Agreement shall operate as a waiver thereof.
                <br/><br/>
                <span className="font-semibold">14.4 Severability : </span> If any provision of this Agreement is held invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.
                <br/><br/>
                <span className="font-semibold">14.5 Assignment : </span> The Gym shall not assign its rights or delegate its obligations under this Agreement without prior written consent from Train Rex.
            </p>
            
            <h3 className="text-2xl font-semibold mb-2">Contact Information</h3>
            <p className="mb-6">For any questions, please contact us at info@trainrex.org</p>
        </div>
    );
}

export default GymServicesAgreement;
