/**
 * Checks if the receipt matches the conditions
 * For being verified
 * @param {Object} receiptData  -   Data of the receipt   
 * @param {String} userID       -   The user ID to check if the receipt is of the correct gym
 * 
 * @returns {Object}            -   status {Boolean}    -   true; if the receipt is valid, false; otherwise
 *                              -   reason {String}     -   Reason for ingenuinity
 */
export const conditionsForVerification = (receiptData, userID) => {
    // Check The Verified Status
    if (receiptData.verified)
        // If the Receipt is already scanned and verified
        return {
            status  :   false,
            reason  :   "Already Verified Receipt"
        };

    // Check if the receipt belongs to the right gym
    if (receiptData.gymID !== userID)
        return {
            status  :   false,
            reason  :   "Not a member of this gym"
        };

    // Check if today is between the membership validity dates
    if (!isReceiptValidToday(receiptData))
        // If it is not valid today
        return {
            status  :   false,
            reason  :   "Expired Receipt"
        };

    // If all checks are passed, return true
    return {
        status  :   true,
        reason  :   "Perfect"
    };
       
}

/**
 * Checks if the current date falls within the receipt's valid period.
 * 
 * @param {Object} receiptData - The receipt object containing startDate and endDate.
 * @returns {Boolean} - Returns true if today lies between startDate and endDate, otherwise false.
 */
const isReceiptValidToday = (receiptData) => {
    if (!receiptData?.startDate || !receiptData?.endDate) {
        console.error("Invalid receipt data: Missing startDate or endDate.");
        return false;
    }

    try {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Normalize to start of the day

        const startDate = new Date(receiptData.startDate);
        const endDate = new Date(receiptData.endDate);

        return today >= startDate && today <= endDate;
    } catch (error) {
        console.error("Error verifying receipt validity:", error);
        return false;
    }
};
