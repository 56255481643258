
import { collection, getDocs, query, where, doc } from "@firebase/firestore";
import { db } from "../../config/firebase";

/**
 * Fetches a payment receipt by searching for a document with the given receipt number.
 * @param {String} receiptID    -   Receipt ID
 * @returns {Object}            -   docData {Object}    -   data of the receipt
 *                              -   docRef {Ref}        -   reference to the document
 */
export const fetchPaymentReceipt = async (receiptID) => {
    try {
        const receiptRef = collection(db, "PaymentReceipts");
        const q = query(receiptRef, where("paymentID", "==", `pay_${receiptID}`));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const docSnapshot = querySnapshot.docs[0]; // Get the first document
            return {
                docData : docSnapshot.data(), // Document data
                docRef  : doc(db, "PaymentReceipts", docSnapshot.id), // Get DocumentReference
            };
        }
        return null; // No matching receipt found
    } catch (error) {
        console.error("Error in fetching Payment Receipt:", error);
        return null;
    }
};