import React, { useState, useEffect } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, createUserWithEmailAndPassword, linkWithCredential, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../config/firebase';
import { doc, setDoc } from '@firebase/firestore';
import { gymList, studioList } from '../../components/Databases';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleSendPersonalizedMessage } from '../../helperFunctions/basicHelper';
import { checkIfUserIsAlreadyRegistered } from '../../helperFunctions/GymFormHelper';

/**
 * Renders a page to sign a gym up
 */
function GymCred() {
    const location                                                  = useLocation();
    const navigate                                                  = useNavigate();
    const [isGymOnboarding          , setIsGymInboarding          ] = useState(location.state.isGymOnboarding || null);
    const [email                    , setEmail                    ] = useState('');
    const [password                 , setPassword                 ] = useState('');
    const [confirmPassword          , setConfirmPassword          ] = useState('');
    const [phone                    , setPhone                    ] = useState('');
    const [verificationCode         , setVerificationCode         ] = useState('');
    const [verificationId           , setVerificationId           ] = useState('');
    const [credential               , setCredential               ] = useState(null);
    const [showEmailPasswordForm    , setShowEmailPasswordForm    ] = useState(false);
    const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false);
    const [phoneNumberSubmitting    , setPhoneNumberSubmitting    ] = useState(false);
    const [verifingCode             , setVerifingCode             ] = useState(false);
    const [submittingEmail          , setSubmittingEmail          ] = useState(false);
    const [countryCode              ,                             ] = useState('+91');


    useEffect (() => {

        // Go back to GymCred if the state is not present
        if (location?.state?.isGymOnboarding === null || location?.state?.isGymOnboarding === undefined) 
            navigate('/GymCredOnboarding');
        // Otherwise move forward
        else
            setIsGymInboarding(location.state.isGymOnboarding);

    }, [location.state]);

    // useEffect (() => {
    //     window.location.reload();
    // }, [isGymOnboarding]);


    /**
     * Initialises Recaptcha upon page loading
     * Used to filter out bots
     */
    useEffect(() => {
        const initializeRecaptcha = async () => {
            if (!window.recaptchaVerifier) {
                try {
                    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                        'size': 'invisible',
                        'callback': (response) => {
                            console.log("reCAPTCHA solved:", response);
                        },
                        'expired-callback': () => {
                            console.log("reCAPTCHA expired");
                        }
                    });
                    await window.recaptchaVerifier.render();
                    console.log('reCAPTCHA initialized');
                } catch (error) {
                    console.error("Error initializing reCAPTCHA", error);
                }
            }
        };
        if (isGymOnboarding !== null) initializeRecaptcha();
    }, [isGymOnboarding]);

    /**
     * Handles the submission of phone Number
     * Checks if recaptcha is verified, if not, returns
     * If yes, authenticates user with their phone number
     * @param {String} phoneNumber 
     * @param {RecaptchaVerifier} appVerifier
     */
    const handlePhoneNumberSubmit = async () => {
        setPhoneNumberSubmitting(true);
        const fullPhoneNumber = `${countryCode}${phone}`;

        const userData = await checkIfUserIsAlreadyRegistered(phone);

        if(userData !== null && userData?.email !== '') {
            alert('You are already registered, please login, if on login, gym data is not complete, you will be redirected to gym form')
            navigate('/')
            return;
        }

        const appVerifier = window.recaptchaVerifier;
    
        if (!window.recaptchaVerifier) {
          console.error("reCAPTCHA verifier is not initialized");
          return;
        }
    
        try {
            const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
            setVerificationId(confirmationResult.verificationId);
            setShowVerificationCodeInput(true);
            setPhoneNumberSubmitting(false);
        } catch (error) {
            console.error("Error during phone number verification", error.message);
            setPhoneNumberSubmitting(false);
            // console.error(error);
        }
    };

    /**
     * Once the verificstion code is sent
     * This fn, verifies the code
     * Changes the form layout -> Email and Password textboxes are now visible
     */
    const handleCodeVerification = async () => {
        try {
            setVerifingCode(true);
            const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
            setCredential(cred);
            setShowEmailPasswordForm(true);
            setVerifingCode(false)
        } catch (error) {
            setVerifingCode(false);
        // console.error("Error verifying code", error);
        }
    };

    /**
     * Adds user to the database
     * @param {User} user 
     * @returns {boolean}
     */
    const addUserToDoc = async(user) => {
        try {
        const userRef = doc(db, isGymOnboarding ? gymList : studioList, user.uid);
        await setDoc(userRef, {
            phoneNumber :   phone,
            email       :   email,
        });
        return true;
        } catch (error) {
        // console.error("Error adding user to Firestore", error);
        return false;
        }
    };

    /**
     * Checks if both passwords are equal
     * Calls addUserToDoc
     * Links User credentials
     * Navigates to /GymForm
     * @param {*} e 
     * @returns 
     */
    const handleSignUp = async (e) => {
        e.preventDefault();
        setSubmittingEmail(true);

        if (password !== confirmPassword) {
            alert('Passwords do not match.');
            setSubmittingEmail(false);
            return;
        }

        try {

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        if (await addUserToDoc(user)) {
            // await updateProfile(user, { displayName: name });
            await linkWithCredential(user, credential);
            
            // If everything is successful, navigate to GymForm page with user data
            try {
                // Extracting necessary data from user object
                const userData = {
                    uid: user.uid,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                };
                
                // Sending personalized messages to gym owners for better communication and standardization
                handleSendPersonalizedMessage('sms', phone, 'gymCred')
                handleSendPersonalizedMessage('email', email, 'gymCred')

                // Navigating to the gym form to fill in the details
                if(isGymOnboarding || isGymOnboarding === null) {
                    setSubmittingEmail(false);
                    navigate('/GymForm', { state: { user: userData } });
                } else {
                    setSubmittingEmail(false);
                    navigate('/StudioForm', { state: { user: userData } });
                }
            } catch (error) {
                setSubmittingEmail(false);
            }
        }
    } catch (error) {
        console.error("Error during sign up", error);
    
        alert('Error during sign up: ' + error.message);
    
        // In case of error, navigate to the start
        navigate('/GymCredOnboarding');
    }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-tertiary">
            <div className="w-[90vw] lg:max-w-1/2 lg:w-1/2 bg-secondary rounded-lg shadow-md overflow-hidden">
                <div className="p-6 sm:p-8">

                    {/* Display state for testing */}
                    {/* {gymOrStudioSelected && (
                        <div className="mt-4 text-green-700">
                        Onboarding Type Selected: {isGymOnboarding ? "Gym" : "Studio"}
                        </div>
                    )} */}

                    <h2 className="text-2xl font-bold text-center text-primary">Register Your {isGymOnboarding ? 'Gym ' : 'Studio'}</h2>
                    {/* Show registration forms only after selection */}
                    <div>
                        {!showEmailPasswordForm ? (
                        <div>
                            {!showVerificationCodeInput ? (
                                <div className="mt-8 space-y-6">
                                    <div className="rounded-md shadow-sm -space-y-px">
                                        <div>
                                            <label htmlFor="phone" className="sr-only">Phone number</label>
                                            <input
                                                id="phone"
                                                name="phone"
                                                type="tel"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                                className="appearance-none bg-white rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-800 text-black rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                                placeholder="Phone number"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        onClick={handlePhoneNumberSubmit}
                                        disabled={phoneNumberSubmitting}
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-xl text-primary bg-tertiary hover:bg-primary hover:text-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:black"
                                    >
                                        {phoneNumberSubmitting ? 'Sending Verification Code...' : 'Send Verification Code'}
                                    </button>
                                    <div id="recaptcha-container"></div>
                                    <div className='flex flex-col lg:flex-row justify-center items-center'>
                                        <div>By Signing Up, you agree to our &nbsp;</div>
                                        <a href='https://trainrex.org/TandCPage' className='text-tertiary underline'>Terms and Conditions&nbsp; </a>
                                        <div onClick={() => navigate('/SignUpWithTNC', {state: isGymOnboarding})} className='cursor-pointer hover:underline text-purple underline'>
                                            Or Sign up using Terms and Conditions
                                        </div>
                                    </div>
                                </div>
                            ) : (
                            <div className="mt-8 space-y-6">
                                <div className="text-center text-primary mb-4">
                                    <span className="font-bold block">
                                        Verification Code has been sent to :
                                    </span>
                                    <span className="font-bold block">
                                        {countryCode} - {phone}
                                    </span>
                                </div>
                                <div>
                                    <label htmlFor="verificationCode" className="sr-only">
                                        Verification Code
                                    </label>
                                <input
                                    id="verificationCode"
                                    name="verificationCode"
                                    type="text"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    required
                                    className="appearance-none bg-white rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-800 text-black focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                    placeholder="Enter verification code"
                                />
                                </div>
                                <button
                                    onClick={handleCodeVerification}
                                    disabled={verifingCode}
                                    className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-xl text-primary bg-tertiary hover:bg-primary hover:text-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${verifingCode ? 'bg-opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {verifingCode ? 'Verifying...' : 'Verify Code'}
                                </button>
                            </div>
                            )}
                        </div>
                        ) : (
                        <form className="mt-8 space-y-6" onSubmit={handleSignUp}>
                            <div className="rounded-md shadow-sm space-y-4">
                            {/* Email Address */}
                            <div className="mt-4">
                                <label htmlFor="email" className="sr-only">
                                Email address
                                </label>
                                <input
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                placeholder="Email address"
                                />
                            </div>

                            {/* Password */}
                            <div className="mt-4">
                                <label htmlFor="password" className="sr-only">
                                Password
                                </label>
                                <input
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                placeholder="Password"
                                />
                            </div>

                            {/* Confirm Password */}
                            <div className="mt-4">
                                <label htmlFor="confirm-password" className="sr-only">
                                Confirm Password
                                </label>
                                <input
                                id="confirm-password"
                                name="confirm-password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-primary placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-md"
                                placeholder="Confirm Password"
                                />
                            </div>
                            </div>
                            <div>
                            <button
                                type="submit"
                                disabled={submittingEmail}
                                className={`group relative w-full flex justify-center py-2 px-4 border border-none text-md font-medium rounded-md text-primary bg-tertiary hover:bg-primary hover:text-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black shadow-md ${submittingEmail ? 'cursor-not-allowed bg-opacity-50' : ''}`}
                            >
                                {submittingEmail ? 'Signing Up...' : 'Sign Up'}
                            </button>
                            </div>
                        </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GymCred;
