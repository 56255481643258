import React, { useState, useEffect } from 'react';
import { getDefaultFormDataState, getDefaultStudioFormDataState, storeCity, storeStudio, uploadImage } from '../../helperFunctions/GymFormHelper';
import SuccessModal from '../../helperFunctions/Modal';
import 'react-phone-number-input/style.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { capitalizeFirstLetterOfEveryWord, formatWordToLowerCase } from '../../helperFunctions/basicHelper';
import TR_Logo from "../../assets/Website_content/TR_Website_content-02.svg"
import { BankDetails, EquipmentSelection, FirstSection, SecondSection } from '../../helperFunctions/StudioFormSections';
import LoadingSpinner from '../../components/LoadingSpinner';
import { phoneNumberChecker } from '../../helperFunctions/ContraintsChecker'
import { sendOnboardingMail } from '../../helperFunctions/EmailsAndMore/SendingEmailToGyms';
import { AddPhotos } from '../../helperFunctions/GymFormSections';

/**
 * Function to generate a random UUID
 * @returns A random ID
 */
function generateRandomUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Function to generate a random email address
 * @returns A random Email address with domain - @trainrex.org
 */
function generateRandomEmail() {
    const domains = ['trainrex.org'];
    const username = Math.random().toString(36).substring(7);
    const domain = domains[Math.floor(Math.random() * domains.length)];
    return `${username}@${domain}`;
}

/**
 * Function to generate a random UK phone number
 * @returns A random UK phone number
 */
function generateRandomUKPhoneNumber() {
    const leadingDigits = ['74', '75', '76', '77', '78', '79'];
    const randomLeadingDigit = leadingDigits[Math.floor(Math.random() * leadingDigits.length)];
    const randomDigits = Math.random().toString().slice(2, 10);
    return `+44 ${randomLeadingDigit} ${randomDigits.slice(0, 3)} ${randomDigits.slice(3)}`;
}


/*
 * This page gets the details of the gym to enter data to the main database
 * This is used to on-board gym on the TrainRex Wesbite and App
 * @param   :   db          -   database management tool
 * @return  :   form data   -   Gym Name
 *                              Gym Locality
 *                              City
 *                              Full Name of the Owner
 */
const StudioForm = () => {
   
    // Get Props from GymCred.js
    const location = useLocation();
    const { user } = location.state || {};  //  Here user is gym
    const navigate = useNavigate();

    
    
    useEffect(() => {
        if (user === undefined) {
          alert("There is no user available, redirecting to GymCred");
          navigate("/GymCredOnboarding");
        }
    }, [user, navigate]); // Runs only when `user` changes
   
    /*
     * -------------------------------------------
     * ------------ THIS BLOCK OF CODE -----------
     * --------- GENERATES A RANDOM USER ---------
     * -------------------------------------------
     */

    // const [user, setUser] = useState({
    //     uid: '',
    //     email: '',
    //     phoneNumber: ''
    // });

    // useEffect(() => {
    //     setUser({
    //         uid         : generateRandomUUID(),
    //         email       : generateRandomEmail(),
    //         phoneNumber : generateRandomUKPhoneNumber(),
    //     });
    // }, []); // Empty dependency array ensures this effect runs only once

    /* --------- RANDOM USER BLOCK ENDS ---------- */

    const [formData, setFormData] = useState(getDefaultStudioFormDataState(user));

    const [currentSection, setCurrentSection] = useState(1);
    const [newEquipment  , setNewEquipment  ] = useState("");
    const [newFacilities , setNewFacilities ] = useState("");
    const [newClasses    , setNewClasses    ] = useState("");
    const [imgURL        , setImgURL        ] = useState();
    const [modalIsOpen   , setModalIsOpen   ] = useState(false);
    const [submitting    , setSubmitting    ] = useState(false);
    const [pricingType   , setPricingType   ] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploading     ,      setUploading] = useState();
    const [selectedOffers, setSelectedOffers] = useState({
        single: true,
        // couple: false,
        // family: false,
    });
    // const [sameTimings    , setSameTimings    ] = useState(false);
    // const [globalTimeSlots, setGlobalTimeSlots] = useState([{ openingTime: '', closingTime: '' }]);

    const addNewClassToPrices = (className) => {
        setFormData((prevState) => {
            const updatedPrices = { ...prevState.prices };
            const updatedDuration = { ...prevState.duration };
        
            // ["single", "couple", "family"].forEach((category) => {
            ["single"].forEach((category) => {
                if (!updatedPrices[category][className]) {
                updatedPrices[category][className] = {
                    dailyPrice: "",
                    monthlyPrice: "",
                    quarterlyPrice: "",
                    halfYearlyPrice: "",
                    yearlyPrice: "",
                    exclusiveDailyPrice: "",
                    exclusiveMonthlyPrice: "",
                    exclusiveQuarterlyPrice: "",
                    exclusiveHalfYearlyPrice: "",
                    exclusiveYearlyPrice: "",
                };
                }
            });

            // Add the class to the duration structure
            if (!updatedDuration[className]) {
                updatedDuration[className] = "";
            }
        
            return {
                ...prevState,
                prices: updatedPrices,
                duration: updatedDuration,
            };
        });
    };

    // Handle Add Class
    const handleAddClass = (className) => {
    if (!className) {
      alert("Please enter a valid class name");
      return;
    }

    addNewClassToPrices(className);
  
    setFormData((prevData) => ({
      ...prevData,
      duration: { ...prevData.duration, [className]: "" },
      availableSlots: { ...prevData.availableSlots, [className]: "" },
      trainerNames: { ...prevData.trainerNames, [className]: "" },
      specialInstructions: { ...prevData.specialInstructions, [className]: "" },
    }));
    };

    // Handle Weekday Schedule for Class
    const handleScheduleChange = (day, className, timingIndex, value) => {
    setFormData((prevData) => {
      const updatedSchedule = { ...prevData.classesSchedule };
      if (!updatedSchedule[day][className]) updatedSchedule[day][className] = [];
      updatedSchedule[day][className][timingIndex] = value;
  
      return { ...prevData, classesSchedule: updatedSchedule };
    });
    };
  
    // Add Time Slot for Class
    const addTimeSlot = (day, className) => {
        setFormData((prevData) => {
        const updatedSchedule = { ...prevData.classesSchedule };
        if (!updatedSchedule[day][className]) updatedSchedule[day][className] = [];
        updatedSchedule[day][className].push("");
    
        return { ...prevData, classesSchedule: updatedSchedule };
        });
    };

    //Remove time slot for classes
    const removeTimeSlot = (day, className, index) => {
        setFormData((prevData) => {
        const updatedSchedule = { ...prevData.classesSchedule };
        updatedSchedule[day][className].splice(index, 1); // Remove the selected time slot
        return { ...prevData, classesSchedule: updatedSchedule };
        });
    };


    // Handle pricing type change
    const handlePricingTypeChange = (e) => {
        const value = e.target.value;
        setPricingType(value);
        setFormData(prevFormData => ({
            ...prevFormData,  // Spread previous form data
            paymentModel: value, // Update the paymentModel with the new value
            exclusiveModel : value==='exclusive'
        }));
    };

    // Handle offers checkbox toggle
    const handleOfferChange = (offer) => {
        setSelectedOffers((prevState) => ({
            ...prevState,
            [offer]: !prevState[offer],
        }));
    };
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePriceChange = (e) => {
        const { name, value } = e.target;
      
        setFormData((prevFormData) => {
          const keys = name.split('.'); // e.g., ['prices', 'single', 'classes', '0', 'price']
      
          const updatedFormData = { ...prevFormData };
          let currentLevel = updatedFormData;
      
          for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            if (!currentLevel[key]) currentLevel[key] = isNaN(parseInt(keys[i + 1])) ? {} : []; 
            currentLevel = currentLevel[key];
          }
      
          currentLevel[keys[keys.length - 1]] = value;
      
          return updatedFormData;
        });
      };

    // Checkbox handler for "Same timings for all days?"
    // const handleSameTimingsChange = (checked) => {
    //     setSameTimings(checked);

    //     if (checked) {
    //         // Synchronize all days with global time slots
    //         setFormData(prevData => {
    //             const updatedSlots = {};
    //             Object.keys(prevData.timeSlots).forEach(day => {
    //                 updatedSlots[day] = [...globalTimeSlots];
    //             });
    //             return { ...prevData, timeSlots: updatedSlots };
    //         });
    //     }
    // };

    // // Global time slot change handler
    // const handleGlobalTimeSlotChange = (index, field, value) => {
    //     const updatedGlobalSlots = [...globalTimeSlots];
    //     updatedGlobalSlots[index] = { ...updatedGlobalSlots[index], [field]: value };
    //     setGlobalTimeSlots(updatedGlobalSlots);

    //     // Update all days with the modified global slots
    //     setFormData(prevData => {
    //         const updatedSlots = {};
    //         Object.keys(prevData.timeSlots).forEach(day => {
    //             updatedSlots[day] = [...updatedGlobalSlots];
    //         });
    //         return { ...prevData, timeSlots: updatedSlots };
    //     });
    // };

    // // Add a new global time slot and sync it across all days
    // const handleAddGlobalTimeSlot = () => {
    //     const newGlobalSlots = [...globalTimeSlots, { openingTime: '', closingTime: '' }];
    //     setGlobalTimeSlots(newGlobalSlots);

    //     setFormData(prevData => {
    //         const updatedSlots = {};
    //         Object.keys(prevData.timeSlots).forEach(day => {
    //             updatedSlots[day] = [...newGlobalSlots];
    //         });
    //         return { ...prevData, timeSlots: updatedSlots };
    //     });
    // };

    // // Remove a global time slot and sync all days
    // const handleRemoveGlobalTimeSlot = (index) => {
    //     const updatedGlobalSlots = globalTimeSlots.filter((_, i) => i !== index);
    //     setGlobalTimeSlots(updatedGlobalSlots);

    //     setFormData(prevData => {
    //         const updatedSlots = {};
    //         Object.keys(prevData.timeSlots).forEach(day => {
    //             updatedSlots[day] = [...updatedGlobalSlots];
    //         });
    //         return { ...prevData, timeSlots: updatedSlots };
    //     });
    // };    

    // const handleAddTimeSlot = (day) => {
    //     setFormData(prevData => ({
    //         ...prevData,
    //         timeSlots: {
    //             ...prevData.timeSlots,
    //             [day]: [...prevData.timeSlots[day], { openingTime: '', closingTime: '' }],
    //         },
    //     }));
    // };
    
    // const handleRemoveTimeSlot = (day, index) => {
    //     setFormData(prevData => ({
    //         ...prevData,
    //         timeSlots: {
    //             ...prevData.timeSlots,
    //             [day]: prevData.timeSlots[day].filter((_, i) => i !== index),
    //         },
    //     }));
    // };
    
    // const handleTimeSlotChange = (day, index, field, value) => {
    //     const updatedSlots = [...formData.timeSlots[day]];
    //     updatedSlots[index] = {
    //         ...updatedSlots[index],
    //         [field]: value,
    //     };
    //     setFormData(prevData => ({
    //         ...prevData,
    //         timeSlots: {
    //             ...prevData.timeSlots,
    //             [day]: updatedSlots,
    //         },
    //     }));
    // };

    // For multiple images upload at once
    // For multiple images upload at once
        const handleMultiImagesUpload = (e) => {
            const files = Array.from(e.target.files);
            setSelectedImages(files);
        };
    
        // uploads the image to firebase storage on clicking upload on last screen
        const handleUploadToFirestore = async () => {
            if (selectedImages?.length === 0) return alert("No images selected!");
    
            setUploading(true);
    
            console.log(1, selectedImages);
            const uploadPromises = selectedImages.map((file) => uploadImage(file, formData?.studio, formatWordToLowerCase(formData?.city), 'studios'));
            const uploadedUrls = await Promise.all(uploadPromises);
    
            setUploading(false);
            alert("Images uploaded successfully!");
            console.log("Uploaded Image URLs:", uploadedUrls);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Portfolio: [...(prevFormData.Portfolio || []), ...uploadedUrls], // Append new images
            }));
        };


    // For Logo: this will convert the image to base64string and this string will be stored in the firebase database and can be fetched along with all the data on the dashboard to display gymLogo
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        const maxWidth = 600;  // Define max width for resizing
        const maxHeight = 600; // Define max height for resizing
        const quality = 0.7;   // Define quality for compression (0.0 to 1.0)
    
        const reader = new FileReader();
        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
    
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;
    
                // Resize the image based on max width and height
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
    
                // Set the canvas dimensions
                canvas.width = width;
                canvas.height = height;
    
                // Draw the image onto the canvas
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
    
                // Convert the canvas to a Base64 string with the specified quality
                const dataUrl = canvas.toDataURL('image/jpeg', quality);
    
                // Set the resized and compressed image as the state
                setImgURL(dataUrl);
            };
        };
    
        reader.readAsDataURL(file);
    };
   
    useEffect(() => {
        if (imgURL) {
            // Update the formData with the imgURL when imgURL changes
            console.log("Img URL : ", imgURL);
            setFormData((prevFormData) => ({ ...prevFormData, studioLogo: imgURL}));
        }
    }, [imgURL]);

    const goToNextSection = () => {
        if(!phoneNumberChecker(`+91${formData.studioContactNo}`)) {
            alert("Please fill the contact number correctly");
            return ; 
        } else {
            setCurrentSection(prevSection => prevSection + 1);
        }
    };

    const goToPreviousSection = () => {
        setCurrentSection(prevSection => prevSection - 1);
    };

    /*
     * Submits the data, and
     * Stores the data in the database
     */
    const handleSubmit = async (e) => {
        e?.preventDefault();
    
        setSubmitting(true);
    
        // Preprocessing Data
        const info = {
            city              : formatWordToLowerCase(formData.city),
            studioName        : formatWordToLowerCase(formData.studio),
            studioDisplayName : capitalizeFirstLetterOfEveryWord(formData.studio),
            locality          : capitalizeFirstLetterOfEveryWord(formData.locality),
            phoneNumber       : formData.phoneNumber,
            Portfolio         : formData?.Portfolio,
            studioContactNo   : `+91${formData.studioContactNo}`,
            owner             : formData.owner,
            address           : capitalizeFirstLetterOfEveryWord(formData.address),
            studioLogo        : formData.studioLogo,
            email             : user.email,
            gstin             : formData.gstin,
            country           : capitalizeFirstLetterOfEveryWord(formData.country),
            isWomensOnly      : formData.isWomensOnly,
            pinCode           : formData.pinCode,
            accountName       : formData.accountName,
            accountCategory   : formData.accountCategory,
            accountNumber     : formData.accountNumber,
            accountType       : formData.accountType,
            ifscCode          : formData.ifscCode,
            duration          : formData.duration,
            classesSchedule   : formData.classesSchedule,
    
            // Adjust timeSlots to include each day and its associated slots
            // timeSlots: Object.fromEntries(
            //     Object.entries(formData.timeSlots).map(([day, slots]) => [
            //         day,
            //         slots.map(slot => ({
            //             openingTime: slot.openingTime,
            //             closingTime: slot.closingTime
            //         }))
            //     ])
            // ),
            
            // Pricing Structure (Single, Couple, Family)
            paymentModel      : pricingType,
            exclusiveModel    : formData.exclusiveModel,
            prices            : formData.prices,
    
            cityDisplayName   : capitalizeFirstLetterOfEveryWord(formData.city),
            equipmentList     : formData.equipmentList,
            facilities        : formData.facilities,
        };
    
        // Storing City
        if (await storeCity(info.city)) {
            console.log('City Added!');
        } else {
            console.log('Failed to add city');
            setSubmitting(false);
            return false; // Exit form on failure
        }
    
        // Storing Gym
        if (await storeStudio(info, user)) {
            console.log('Studio Added!');
        } else {
            console.log('Failed to add Studio!');
            setSubmitting(false);
            return false;
        }
    
        // Clear form data after successful submission
        setFormData(getDefaultFormDataState({
            phoneNumber     :   '',
            email           :   ''
        }));
        setSubmitting(false);
        
        // Send Onboarding Success Email to the studio
        sendOnboardingMail(info.email, info.studioDisplayName, `/${info.city}/gyms/${info.studioName}/`);

        // Display Alert Message
        setModalIsOpen(true);
    };

    //Scroll to top whenever section changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentSection]);

    console.log("Formdata : ", formData)

    return (
        <div className="flex flex-col bg-tertiary px-6 lg:px-[12vw] py-14">
            <div className='flex justify-between items-center mb-12 md:mb-14'>
                <img src={TR_Logo} alt="Train Rex Logo" className='h-6 md:h-8 lg:h-[2.2vw] object-cover' />
                <h1 className="flex text-left text-xl lg:text-[2vw] font-bold ">Studio Details</h1>
            </div>
            
            <form onSubmit={handleSubmit}>

                {/* Section 1: Basic Information */}
                {currentSection === 1 && (
                    <FirstSection 
                        formData                    ={formData} 
                        user                        ={user} 
                        handleChange                ={handleChange} 
                        goToNextSection             ={goToNextSection} 
                        handleMultiImagesUpload     ={handleMultiImagesUpload} 
                        handleUploadToFirestore     ={handleUploadToFirestore}
                        uploading                   ={uploading}
                        handleImageChange           ={handleImageChange} 
                        // handleAddTimeSlot           ={handleAddTimeSlot} 
                        // handleRemoveTimeSlot        ={handleRemoveTimeSlot} 
                        // handleTimeSlotChange        ={handleTimeSlotChange} 
                        // sameTimings                 ={sameTimings} 
                        // handleSameTimingsChange     ={handleSameTimingsChange} 
                        // globalTimeSlots             ={globalTimeSlots} 
                        // handleGlobalTimeSlotChange  ={handleGlobalTimeSlotChange} 
                        // handleAddGlobalTimeSlot     ={handleAddGlobalTimeSlot} 
                        // handleRemoveGlobalTimeSlot  ={handleRemoveGlobalTimeSlot} 
                    />
                )}

                {/* Classes Details Except Prices */}
                {currentSection === 2 && (
                    <SecondSection 
                        formData            ={formData}
                        setFormData         ={setFormData}
                        handleAddClass      ={handleAddClass}
                        handleScheduleChange={handleScheduleChange}
                        addTimeSlot         ={addTimeSlot}
                        removeTimeSlot      ={removeTimeSlot}
                        goToPreviousSection ={goToPreviousSection}
                        goToNextSection     ={goToNextSection}
                    />
                )}


                {/* Section 3: Owner Information */}
                {currentSection === 3 && (
                    <>
                        <div className="mb-8">
                            <h2 className="flex text-left text-xl lg:text-[1.25vw] font-semibold mb-4 lg:mb-[1vw]">Owner Information</h2>

                            <div className="mb-8 flex items-center">
                                <label className="flex text-left w-1/4 text-sm lg:text-[1.1vw] font-medium">
                                    Owner's Name
                                </label>
                                <input
                                    type="text"
                                    name="owner"
                                    value={formData.owner}
                                    onChange={handleChange}
                                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-md lg:text-[1vw]"
                                />
                            </div>

                            {/* Bank Details */}
                            <BankDetails formData={formData} handleChange={handleChange}/>
                        </div>

                        {/* Choose Payment Model */}
                        <div className="mb-4">
                            {/* <h2 className="flex text-left text-xl lg:text-[1.25vw] font-semibold mb-4 lg:mb-[1vw]">
                                Payment Model
                            </h2> */}

                            {/* Pricing Type Selector */}
                            {/* <div className="relative mb-4 flex justify-center gap-10 rounded-3xl border-[.25vw] border-secondary w-[89vw] md:w-[94vw] lg:w-[76vw] h-[35vh]">
                                Slide 1: Pricing Type Selector
                                {currentSlide === 1 && (
                                    <SlideOne 
                                    formData                ={formData} 
                                    selectedOffers          ={selectedOffers} 
                                    pricingType             ={pricingType} 
                                    currentSlide            ={currentSlide} 
                                    setCurrentSlide         ={setCurrentSlide} 
                                    handlePricingTypeChange ={handlePricingTypeChange}/>
                                )}

                                Slide 2: Offers
                                {currentSlide === 2 && (
                                    <SlideTwo 
                                    selectedOffers      ={selectedOffers} 
                                    currentSlide        ={currentSlide} 
                                    setCurrentSlide     ={setCurrentSlide} 
                                    pricingType         ={pricingType} 
                                    handleOfferChange   ={handleOfferChange}/>
                                )} */}
                                
                                {/* Pricing Details Ahead */}
                                {/* {currentSlide === 3 && pricingType === "commission" && (
                                    <Exclusive userType="single" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers} handleScheduleChange={handleScheduleChange}/>
                                )}

                                {currentSlide === 3 && pricingType === "exclusive"  && (
                                    <Exclusive  userType="single" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers} handleScheduleChange={handleScheduleChange}/>
                                )} */}

                                {/* {currentSlide === 4 && pricingType === "commission" && (
                                    <Exclusive userType="couple" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers} handleScheduleChange={handleScheduleChange}/>
                                )}

                                {currentSlide === 4 && pricingType === "exclusive"  && (
                                    <Exclusive  userType="couple" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers} handleScheduleChange={handleScheduleChange}/>
                                )}

                                {currentSlide === 5 && pricingType === "commission" && (
                                    <Exclusive userType="family" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers} handleScheduleChange={handleScheduleChange}/>
                                )}

                                {currentSlide === 5 && pricingType === "exclusive"  && (
                                    <Exclusive  userType="family" formData={formData} handlePriceChange={handlePriceChange} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} selectedOffers={selectedOffers} handleScheduleChange={handleScheduleChange}/>
                                )}

                                {currentSlide === 6 && pricingType === "commission" && (
                                    <ReviewExclusivePrices formData={formData} selectedOffers={selectedOffers} setCurrentSlide={setCurrentSlide}/>
                                )}

                                {currentSlide === 6 && pricingType === "exclusive" && (
                                    <ReviewExclusivePrices  formData={formData} selectedOffers={selectedOffers} setCurrentSlide={setCurrentSlide}/>
                                )} */}
                            {/* </div> */}

                            {/* Navigation buttons */}
                            <div className="flex justify-center gap-10 mt-20 ">
                                <button type='button' onClick={goToPreviousSection} className="px-4 py-2 bg-secondary w-28 rounded-full text-tertiary hover:bg-primary hover:scale-110 transition-all duration-300">
                                    &lt; Back
                                </button>
                                <button 
                                    type='button'
                                    onClick={goToNextSection} 
                                    disabled={!formData.owner || !formData.accountCategory || !formData.accountNumber}
                                    className={`px-4 py-2 bg-secondary w-28 rounded-full text-tertiary hover:bg-primary hover:scale-110 transition-all duration-300 ${(!formData.owner || !formData.accountCategory || !formData.accountNumber) ? 'opacity-50 cursor-default' : ''}`}
                                >
                                    Next &gt;
                                </button>
                            </div>
                        </div>
                    </>
                )}    


                {currentSection === 4 && (
                    <EquipmentSelection 
                    formData={formData} 
                    setFormData={setFormData} 
                    submitting={submitting} 
                    newFacilities={newFacilities} 
                    setNewFacilities={setNewFacilities} 
                    newEquipment={newEquipment} 
                    setNewEquipment={setNewEquipment}
                    goToPreviousSection={goToPreviousSection} 
                    goToNextSection={goToNextSection}
                    />
                )}

                {currentSection === 5 && 
                    <AddPhotos 
                        formData                =   {formData} 
                        submitting              =   {submitting}
                        handleImageChange       =   {handleImageChange}
                        handleMultiImagesUpload =   {handleMultiImagesUpload}
                        handleUploadToFirestore =   {handleUploadToFirestore}
                        goToPreviousSection     =   {goToPreviousSection} 
                        uploading               =   {uploading}
                        selectedImages          =   {selectedImages}
                        handleSubmit            =   {handleSubmit}
                        isGymForm               =   {false}
                    />
                }
            </form>

            {submitting && 
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="h-40 w-40">
                    <LoadingSpinner />
                    </div>
                </div>
            }

            <SuccessModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        </div>
    );
};

export default StudioForm;